import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import { DeviceProps } from '..';

type Props = DeviceProps & {
  onSend: (temp: string) => void;
};

const InputTermometro = (props: Props) => {
  const { onClose, onSend, open } = props;
  const [temp, setTemp] = useState('');
  const [erroreTemp, setErroreTemp] = useState('');

  const handleClose = () => {
    setTemp('');
    setErroreTemp('');
    onClose();
  };

  const handleSend = () => {
    if (checkValidNumber(temp)) {
      setErroreTemp('');
      setTemp('');
      onSend(temp);
    } else {
      setErroreTemp('Controllare Valore Temperatura');
    }
  };

  const checkValidNumber = (inputValue: string) => {
    return parseFloat(inputValue) == 0 || parseFloat(inputValue);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>Termometro</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <img
            src={'assets/device/ic_termometro.png'}
            style={{ width: 100, alignSelf: 'center' }}
          />
          <div>
            <form autoComplete="off">
              <TextField
                variant="standard"
                required
                label="Temperatura"
                inputProps={{
                  maxLength: 4,
                  inputMode: 'decimal',
                  pattern: '^[0-9]{0,2}(\\.[0-9]{0,1})?$'
                }}
                fullWidth
                value={temp}
                error={erroreTemp.length == 0 ? false : true}
                helperText={erroreTemp.length == 0 ? '' : erroreTemp}
                style={{ marginTop: 10, marginBottom: 10 }}
                onChange={(e) => {
                  if (checkValidNumber(e.target.value)) {
                    setErroreTemp('');
                  } else {
                    setErroreTemp('Controllare Valore Temperatura');
                  }
                  setTemp(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </form>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annulla
        </Button>
        <Button onClick={handleSend} color="primary" autoFocus>
          Invia
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InputTermometro;
