import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import { DeviceProps } from '..';

type Props = DeviceProps & {
  onSend: (spo2: string, pr: string) => void;
};

const InputOssimetro = (props: Props) => {
  const { onClose, onSend, open } = props;
  const [spo2, setSpo2] = useState('');
  const [pr, setPr] = useState('');
  const [erroreSPO2, setErroreSPO2] = useState('');
  const [errorePR, setErrorePR] = useState('');

  const handleClose = () => {
    setSpo2('');
    setPr('');
    setErroreSPO2('');
    setErrorePR('');
    onClose();
  };

  const handleSend = () => {
    if (checkValidNumber(spo2)) {
      setErroreSPO2('');
      if (checkValidNumber(pr)) {
        setErrorePR('');
        setSpo2('');
        setPr('');
        onSend(spo2, pr);
      } else {
        setErrorePR('Controllare Valore PR');
      }
    } else {
      setErroreSPO2('Controllare Valore SPO2');
    }
  };

  const checkValidNumber = (inputValue: string) => {
    return parseFloat(inputValue) == 0 || parseFloat(inputValue);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>Ossimetro</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <img src={'assets/device/ic_ossimetro.png'} style={{ width: 100, alignSelf: 'center' }} />
          <div>
            <form autoComplete="off">
              <TextField
                variant="standard"
                required
                label="SPO2"
                fullWidth
                inputProps={{
                  maxLength: 3,
                  inputMode: 'decimal',
                  pattern: '[0-9]*'
                }}
                value={spo2}
                error={erroreSPO2.length == 0 ? false : true}
                helperText={erroreSPO2.length == 0 ? '' : erroreSPO2}
                style={{ marginTop: 10, marginBottom: 10 }}
                onChange={(e) => {
                  if (checkValidNumber(e.target.value)) {
                    setErroreSPO2('');
                  } else {
                    setErroreSPO2('Controllare Valore SPO2');
                  }
                  setSpo2(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                variant="standard"
                required
                label="PR"
                inputProps={{
                  maxLength: 3,
                  inputMode: 'decimal',
                  pattern: '[0-9]*'
                }}
                fullWidth
                value={pr}
                error={errorePR.length == 0 ? false : true}
                helperText={errorePR.length == 0 ? '' : errorePR}
                style={{ marginTop: 10, marginBottom: 10 }}
                onChange={(e) => {
                  if (checkValidNumber(e.target.value)) {
                    setErrorePR('');
                  } else {
                    setErrorePR('Controllare Valore PR');
                  }
                  setPr(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </form>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annulla
        </Button>
        <Button onClick={handleSend} color="primary" autoFocus>
          Invia
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InputOssimetro;
