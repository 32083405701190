import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import { DeviceProps } from '..';

type Props = DeviceProps & {
  onSend: (peso: string) => void;
};

const InputBilancia = (props: Props) => {
  const { onClose, onSend, open } = props;
  const [peso, setPeso] = useState('');
  const [errorePeso, setErrorePeso] = useState('');

  const handleClose = () => {
    setPeso('');
    setErrorePeso('');
    onClose();
  };

  const handleSend = () => {
    if (checkValidNumber(peso)) {
      setErrorePeso('');
      setPeso('');
      onSend(peso);
    } else {
      setErrorePeso('Controllare Valore Peso');
    }
  };

  const checkValidNumber = (inputValue: string) => {
    return parseFloat(inputValue) == 0 || parseFloat(inputValue);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>Bilancia</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <img src={'assets/device/ic_bilancia.png'} style={{ width: 100, alignSelf: 'center' }} />
          <div>
            <form autoComplete="off">
              <TextField
                variant="standard"
                required
                label="Peso"
                inputProps={{
                  maxLength: 3,
                  inputMode: 'decimal',
                  pattern: '[0-9]*'
                }}
                fullWidth
                value={peso}
                error={errorePeso.length == 0 ? false : true}
                helperText={errorePeso.length == 0 ? '' : errorePeso}
                style={{ marginTop: 10, marginBottom: 10 }}
                onChange={(e) => {
                  if (checkValidNumber(e.target.value)) {
                    setErrorePeso('');
                  } else {
                    setErrorePeso('Controllare Valore Peso');
                  }
                  setPeso(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </form>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annulla
        </Button>
        <Button onClick={handleSend} color="primary" autoFocus>
          Invia
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InputBilancia;
