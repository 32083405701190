import React, { useEffect, useRef, CSSProperties } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record';
import data from './constant';
import './microphoneVisualizer.css';

type Props = {
  container?: string;
  deviceId?: string;
  heightVisualizer?: number;
  style?: CSSProperties;
};

const MicrophoneVisualizer: React.FC<Props> = (props: Props) => {
  const audioStreamRef = useRef<MediaStream | null>(null);
  const wavesurfer = useRef<WaveSurfer>(undefined);

  useEffect(() => {
    // Funzione per inizializzare Wavesurfer.js
    const initializeWaveSurfer = (deviceId: string) => {
      const children = document.getElementById('mic');
      if (children !== null) {
        const e = children.querySelector('div');
        console.log(e);
        if (e !== null) {
          let first = e.firstElementChild;
          while (first) {
            first.remove();
            first = e.firstElementChild;
          }
        }
      }

      if (wavesurfer.current) {
        wavesurfer.current.destroy();
      }
      // Create an instance of WaveSurfer
      wavesurfer.current = WaveSurfer.create({
        container: props.container ? '#' + props.container : '#mic',
        waveColor: data.primary,
        progressColor: data.primary,
        height: props.heightVisualizer ? props.heightVisualizer : 120
      });

      const record = wavesurfer.current.registerPlugin(RecordPlugin.create());

      record.startRecording({ deviceId });
    };

    // Funzione per richiedere l'accesso al microfono
    const requestMicrophoneAccess = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioStreamRef.current = stream;
        initializeWaveSurfer(stream.id);
      } catch (error) {
        console.error("Errore durante l'accesso al microfono:", error);
      }
    };

    if (!props.deviceId) {
      requestMicrophoneAccess();
    } else {
      initializeWaveSurfer(props.deviceId);
    }

    // Funzione di cleanup per fermare la cattura audio e rilasciare le risorse
    return () => {
      if (audioStreamRef.current) {
        audioStreamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, [props.heightVisualizer, props.container, props.deviceId]);

  return (
    <div id="mic-container" style={props.style}>
      <div id={props.container ? props.container : 'mic'} />
    </div>
  );
};

export default MicrophoneVisualizer;
