/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */

import React from 'react';
import './index.css';
import { FormControl, NativeSelect } from '@mui/material';
import RecordStanza from '@src/network/model/record';

type Props = {
  parti: RecordStanza[];
  parteSelezionata: string;
  onchangeParte: React.ChangeEventHandler<HTMLSelectElement> | undefined;
};

const PlayerBar = (props: Props) => {
  const { parti, parteSelezionata, onchangeParte } = props;

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}>
      <div>
        <FormControl style={{ minWidth: 120, marginLeft: 10 }}>
          <NativeSelect value={parteSelezionata} onChange={onchangeParte}>
            <option key={-1} value={'seleziona'}>
              Seleziona Parte
            </option>
            {parti.map((element, i) => (
              <option key={i} value={element.name}>
                {element.name.charAt(0).toUpperCase() + element.name.slice(1)}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
      </div>
    </div>
  );
};

export default PlayerBar;
