import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import { DeviceProps } from '..';

type Props = DeviceProps & {
  onSend: (sys: string, dia: string) => void;
};

const InputSfigmomanometro = (props: Props) => {
  const { onClose, onSend, open } = props;
  const [sys, setSys] = useState('');
  const [dia, setDia] = useState('');
  const [erroreSys, setErroreSys] = useState('');
  const [erroreDia, setErroreDia] = useState('');

  const handleClose = () => {
    setSys('');
    setDia('');
    setErroreSys('');
    setErroreDia('');
    onClose();
  };

  const handleSend = () => {
    if (checkValidNumber(sys)) {
      setErroreSys('');
      if (checkValidNumber(dia)) {
        setErroreDia('');
        setSys('');
        setDia('');
        onSend(sys, dia);
      } else {
        setErroreDia('Controllare Valore SYS');
      }
    } else {
      setErroreSys('Controllare Valore DIA');
    }
  };

  const checkValidNumber = (inputValue: string) => {
    return parseFloat(inputValue) == 0 || parseFloat(inputValue);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>Sfigmomanometro</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <img
            src={'assets/device/ic_sfigmomanometro.png'}
            style={{ width: 100, alignSelf: 'center' }}
          />
          <div>
            <form autoComplete="off">
              <TextField
                variant="standard"
                required
                label="SYS (Massima)"
                inputProps={{
                  maxLength: 3,
                  inputMode: 'decimal',
                  pattern: '[0-9]*'
                }}
                fullWidth
                value={sys}
                error={erroreSys.length == 0 ? false : true}
                helperText={erroreSys.length == 0 ? '' : erroreSys}
                style={{ marginTop: 10, marginBottom: 10 }}
                onChange={(e) => {
                  if (checkValidNumber(e.target.value)) {
                    setErroreSys('');
                  } else {
                    setErroreSys('Controllare Valore SYS');
                  }
                  setSys(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                variant="standard"
                required
                label="DIA (Minima)"
                inputProps={{
                  maxLength: 3,
                  inputMode: 'decimal',
                  pattern: '[0-9]*'
                }}
                fullWidth
                value={dia}
                error={erroreDia.length == 0 ? false : true}
                helperText={erroreDia.length == 0 ? '' : erroreDia}
                style={{ marginTop: 10, marginBottom: 10 }}
                onChange={(e) => {
                  if (checkValidNumber(e.target.value)) {
                    setErroreDia('');
                  } else {
                    setErroreDia('Controllare Valore DIA');
                  }
                  setDia(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </form>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annulla
        </Button>
        <Button onClick={handleSend} color="primary" autoFocus>
          Invia
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InputSfigmomanometro;
