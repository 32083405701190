import React from 'react';
import './index.css';
import List from '@mui/material/List';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import StopIcon from '@mui/icons-material/Stop';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import data from '@src/components/constant';
import version from '@src/../package.json';
import Stanza from '@src/network/model/stanza';
import Partecipante from '@src/network/model/partecipante';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    height: 'calc(100% - 60px)',
    backgroundColor: data.grey
  }
}));

type Props = {
  datistanza: Stanza;
  partecipante: Partecipante;
  recAttiva: string;
  startRecording: () => void;
  stopRecording: () => void;
  exitSession: () => void;
  closeSession: () => void;
};

const MenuUtente = (props: Props) => {
  const classes = useStyles();

  const { datistanza, partecipante, recAttiva } = props;
  console.log(
    'MenuUtente',
    partecipante.username,
    datistanza.idModeratore,
    partecipante.username === datistanza.idModeratore
  );
  console.log('recAttiva', recAttiva);
  const drawer = (
    <div className={classes.drawer}>
      {partecipante !== undefined ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            paddingTop: 20,
            paddingLeft: 20,
            paddingBottom: 20,
            background: 'lightgray'
          }}>
          <span style={{ fontWeight: 'bold' }}>Salve,</span>
          <span>
            {partecipante.cognome.replace('&#39;', "'")} {partecipante.nome.replace('&#39;', "'")}
          </span>
        </div>
      ) : null}
      {partecipante.username === datistanza.idModeratore ? (
        <List>
          {recAttiva === 'false' ? (
            <ListItem
              button
              key="start_record"
              onClick={() => props.startRecording()}
              style={{ color: data.black, height: 60 }}>
              <ListItemIcon>
                <FiberManualRecordIcon style={{ color: data.black }} />
              </ListItemIcon>
              <ListItemText primary={'Avvia Registrazione'} />
            </ListItem>
          ) : null}
          {recAttiva === 'true' ? (
            <ListItem
              button
              key="stop_record"
              onClick={() => props.stopRecording()}
              style={{ color: data.black, height: 60 }}>
              <ListItemIcon>
                <StopIcon style={{ color: data.black }} />
              </ListItemIcon>
              <ListItemText primary={'Ferma Registrazione'} />
            </ListItem>
          ) : null}
          <ListItem
            button
            key="exit_session"
            onClick={() => props.exitSession()}
            style={{ color: data.black, height: 60 }}>
            <ListItemIcon>
              <ExitToAppIcon style={{ color: data.black }} />
            </ListItemIcon>
            <ListItemText primary={'Esci'} />
          </ListItem>
          <ListItem
            button
            key="close_session"
            onClick={() => props.closeSession()}
            style={{ color: data.black, height: 60 }}>
            <ListItemIcon>
              <ExitToAppIcon style={{ color: data.black }} />
            </ListItemIcon>
            <ListItemText primary={'Chiudi Sessione'} />
          </ListItem>
        </List>
      ) : (
        <List>
          <ListItem
            button
            key="exit_session"
            onClick={() => props.exitSession()}
            style={{ color: data.black, height: 60 }}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={'Esci'} />
          </ListItem>
        </List>
      )}
      {datistanza !== undefined ? (
        <div className="sessione">Sessione: {datistanza.roomId}</div>
      ) : null}
      <div className="version" style={{ bottom: 60 }}>
        v. {version.version} del {version.date_version}
      </div>
    </div>
  );

  return drawer;
};

export default MenuUtente;
