/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import './index.scss';
import { IconButton, Grid, List, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import data from '@src/components/constant';
import { v4 as uuidv4 } from 'uuid';
import MeasureStanza from '@src/network/model/measure';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { urlBackEnd } from '@src/network/restAPI';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden'
  },
  drawer: {
    flexShrink: 0,
    height: 'calc(100% - 60px)',
    backgroundColor: data.grey
  }
}));

type Props = {
  showDevices?: boolean;
  showUpload?: boolean;
  onPressDevice?: (key: string) => void;
  misurazioniDevice: MeasureStanza[];
  cameraAttiva?: boolean;
  openAudio: (url: string) => void;
  username?: string;
};

const MenuTelemedicina = (props: Props) => {
  const {
    showDevices = false,
    showUpload = false,
    onPressDevice,
    misurazioniDevice,
    cameraAttiva,
    openAudio,
    username
  } = props;

  const [showCamere, setShowCamere] = useState(true);
  const [device, setDevice] = useState<string[]>([]);
  const [camera, setCamera] = useState<string[]>([]);
  const classes = useStyles();

  useEffect(() => {
    if (
      navigator.userAgent.indexOf('APP_SMARTLEA') > -1 ||
      navigator.userAgent.indexOf('APP_CUREDOM') > -1
    ) {
      if (navigator.userAgent.indexOf('AMBULATORIO') > -1) {
        console.log('Sono in Ambulatorio');
        setDevice([
          'ossimetro',
          'sfigmomanometro',
          'bilancia',
          'glucometro',
          'termometro',
          'spirometro',
          'ecg',
          'stetoscopio'
        ]);
        setCamera(['camera1', 'camera2', 'camera3']);
        setShowCamere(true);
      } else {
        console.log('Sono su Tablet Android');
        if (username) {
          if (username.indexOf('ass_') > -1) {
            setDevice([
              'ossimetro',
              'sfigmomanometro',
              'bilancia',
              'glucometro',
              'termometro',
              'spirometro'
            ]);
            setShowCamere(false);
          } else {
            setDevice([
              'ossimetro',
              'sfigmomanometro',
              'bilancia',
              'glucometro',
              'termometro',
              'spirometro',
              'ecg',
              'stetoscopio'
            ]);
            setShowCamere(false);
          }
        }
      }
    } else {
      setDevice([
        'ossimetro',
        'sfigmomanometro',
        'bilancia',
        'glucometro',
        'termometro',
        'spirometro'
      ]);
    }
  }, [username]);

  const onPress = (_device: string) => {
    onPressDevice ? onPressDevice(_device) : null;
  };

  const visualizzaDocumento = (mimetype: string, urlFile: string) => {
    if (urlFile !== undefined) {
      const _url = urlFile.replace('/public/', '/stanza/file/');

      if (
        navigator.userAgent.indexOf('APP_SMARTLEA') > -1 ||
        navigator.userAgent.indexOf('APP_CUREDOM') > -1 ||
        navigator.userAgent.indexOf('APP_SL_RN') > -1
      ) {
        if ((window as any).JSInterface) {
          if (_url.includes('/stanza/file/')) {
            (window as any).JSInterface.visualizzaFile(mimetype, urlBackEnd + _url);
          } else {
            (window as any).JSInterface.visualizzaFile(mimetype, _url);
          }
        }
      } else {
        const win = window.open(_url, '_blank');
        if (win !== null) {
          win.focus();
        }
      }
    }
  };

  const drawer = (
    <div className={classes.drawer} id="drawer">
      <div
        style={{
          width: '100%',
          height: '100%'
        }}>
        {showDevices ? (
          <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
            <span
              style={{
                paddingLeft: 10,
                paddingTop: 10,
                fontSize: 18,
                fontWeight: 'bold',
                marginTop: 10
              }}>
              Dispositivi
            </span>
            <div className={classes.root}>
              <Grid container spacing={2} style={{ margin: 5 }}>
                {device.map((item) => (
                  <Grid key={item} item xs={4} lg={3}>
                    <IconButton
                      value={item}
                      size="small"
                      style={{
                        borderRadius: 5,
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#ffffff'
                      }}
                      onClick={() => onPress(item)}>
                      <img
                        style={{
                          objectFit: 'cover',
                          width: '15vw',
                          maxWidth: 70,
                          backgroundColor: '#fff',
                          marginBottom: 25,
                          marginTop: 10
                        }}
                        src={'assets/device/ic_' + item + '.png'}
                        alt={item}
                      />
                      <ImageListItemBar title={item.charAt(0).toUpperCase() + item.slice(1)} />
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
            </div>

            {showCamere === true && camera.length > 0 ? (
              <>
                <span
                  style={{
                    paddingLeft: 10,
                    paddingTop: 10,
                    fontSize: 18,
                    fontWeight: 'bold',
                    marginTop: 10
                  }}>
                  Camere
                </span>
                <div className={classes.root}>
                  <Grid container spacing={2} style={{ margin: 5 }}>
                    {camera.map((item) => (
                      <Grid key={item} item xs={4} lg={3}>
                        <IconButton
                          value={item}
                          size="small"
                          style={{
                            borderRadius: 5,
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#ffffff'
                          }}
                          onClick={() => (cameraAttiva === false ? onPress(item) : null)}>
                          <img
                            style={{
                              objectFit: 'cover',
                              width: 100,
                              height: 100,
                              backgroundColor: '#fff'
                            }}
                            src={'assets/device/ic_' + item + '.png'}
                            alt={item}
                          />
                          <ImageListItemBar title={item.charAt(0).toUpperCase() + item.slice(1)} />
                        </IconButton>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </>
            ) : null}
          </div>
        ) : null}
        <div
          className={!showUpload ? 'esami-misurazioni' : undefined}
          style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
          <span
            style={{
              paddingTop: 10,
              fontSize: 18,
              fontWeight: 'bold',
              margin: 10
            }}>
            Esami e Misurazioni
          </span>
          <List style={{ height: '100%', width: '100%', overflow: 'auto', background: '#eeeeee' }}>
            {misurazioniDevice.map((content) => {
              if (
                content.device !== 'ecg' &&
                content.device !== 'spirometro' &&
                content.device !== 'stetoscopio'
              ) {
                return (
                  <div style={{ padding: 5 }} key={uuidv4()}>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                      <div
                        style={{
                          alignSelf: 'center',
                          flex: 1,
                          display: 'inline-flex',
                          background: '#ffffff',
                          borderRadius: 5
                        }}>
                        <img
                          style={{ width: 40, margin: 10, height: 40 }}
                          alt={'assets/device/ic_' + content.device + '.png'}
                          src={'assets/device/ic_' + content.device + '.png'}
                        />
                        <div
                          style={{
                            alignSelf: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            width: '50%'
                          }}>
                          {content.misurazioni.map((item) => (
                            <div
                              key={uuidv4()}
                              style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'row'
                              }}>
                              <div
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                  flexDirection: 'column'
                                }}>
                                <span
                                  style={{
                                    wordBreak: 'break-all',
                                    fontSize: 14,
                                    fontWeight: 'bold'
                                  }}>
                                  {item.key}
                                </span>
                                <span
                                  style={{
                                    wordBreak: 'break-all',
                                    fontSize: 14
                                  }}>
                                  {item.value} {item.unit}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginRight: 5
                          }}>
                          <span style={{ fontWeight: 'bold', fontSize: 14 }}>Data</span>
                          <span style={{ fontSize: 12 }}>
                            {new Date(parseInt(content.timestamp)).toLocaleString()}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div style={{ padding: 5 }} key={uuidv4()}>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                      <div
                        style={{
                          alignSelf: 'center',
                          flex: 1,
                          display: 'inline-flex',
                          background: '#ffffff',
                          borderRadius: 5
                        }}>
                        <img
                          style={{ width: 40, margin: 10, height: 40 }}
                          alt={'assets/device/ic_' + content.device + '.png'}
                          src={'assets/device/ic_' + content.device + '.png'}
                        />
                        <div
                          style={{
                            alignSelf: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            width: '50%'
                          }}>
                          <div
                            key={uuidv4()}
                            style={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'row'
                            }}>
                            <div
                              style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                justifyContent: 'center'
                              }}>
                              <span
                                style={{
                                  wordBreak: 'break-all',
                                  fontSize: 14,
                                  fontWeight: 'bold'
                                }}>
                                {content.misurazioni[0].key}
                              </span>
                            </div>
                            {content.misurazioni[0].key !== 'VC' ? (
                              <div>
                                <Button
                                  style={{
                                    margin: 10,
                                    backgroundColor: data.primary
                                  }}
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    if (content.device === 'stetoscopio') {
                                      openAudio(content.misurazioni[0].value);
                                    } else {
                                      visualizzaDocumento(
                                        'application/pdf',
                                        content.misurazioni[0].value
                                      );
                                    }
                                  }}>
                                  Apri
                                </Button>
                              </div>
                            ) : (
                              <div style={{ display: 'flex', flex: 1 }}>
                                <span
                                  style={{
                                    wordBreak: 'break-all',
                                    fontSize: 14
                                  }}>
                                  {content.misurazioni[0].value} {content.misurazioni[0].unit}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginRight: 5
                          }}>
                          <span style={{ fontWeight: 'bold', fontSize: 14 }}>Data</span>
                          <span style={{ fontSize: 12 }}>
                            {new Date(parseInt(content.timestamp)).toLocaleString()}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </List>
        </div>
      </div>
    </div>
  );

  return drawer;
};

export default MenuTelemedicina;
