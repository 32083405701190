import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import { DeviceProps } from '..';

type Props = DeviceProps & {
  onSend: (gli: string) => void;
};

const InputGlucometro = (props: Props) => {
  const { onClose, onSend, open } = props;
  const [gli, setGli] = useState('');
  const [erroreGli, setErroreGli] = useState('');

  const handleClose = () => {
    setGli('');
    setErroreGli('');
    onClose();
  };

  const handleSend = () => {
    if (checkValidNumber(gli)) {
      setErroreGli('');
      setGli('');
      onSend(gli);
    } else {
      setErroreGli('Controllare Valore Glicemia');
    }
  };

  const checkValidNumber = (inputValue: string) => {
    return parseFloat(inputValue) == 0 || parseFloat(inputValue);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>Glucometro</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <img
            src={'assets/device/ic_glucometro.png'}
            style={{ width: 100, alignSelf: 'center' }}
          />
          <div>
            <form autoComplete="off">
              <TextField
                variant="standard"
                required
                label="Glicemia"
                inputProps={{
                  maxLength: 3,
                  inputMode: 'decimal',
                  pattern: '[0-9]*'
                }}
                fullWidth
                value={gli}
                error={erroreGli.length == 0 ? false : true}
                helperText={erroreGli.length == 0 ? '' : erroreGli}
                style={{ marginTop: 10, marginBottom: 10 }}
                onChange={(e) => {
                  if (checkValidNumber(e.target.value)) {
                    setErroreGli('');
                  } else {
                    setErroreGli('Controllare Valore Glicemia');
                  }
                  setGli(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </form>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annulla
        </Button>
        <Button onClick={handleSend} color="primary" autoFocus>
          Invia
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InputGlucometro;
