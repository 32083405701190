import React from 'react';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import data from '../../constant';

type Props = {
  title?: string;
  message: string;
  visible: boolean;
};

const MaterialProgressDialog = (props: Props) => {
  /**
   * Props
   */
  const { title = 'Attenzione', message = 'SubTitle', visible } = props;

  /**
   * Vista
   */
  return (
    <Dialog open={visible}>
      <div
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          display: 'flex',
          maxWidth: 450,
          minHeight: 100
        }}>
        <div>
          <CircularProgress style={{ margin: 20, height: 30, width: 30, color: data.primary }} />
        </div>
        <div
          style={{
            flexDirection: 'column',
            marginLeft: 10,
            marginTop: 20,
            marginRight: 20,
            marginBottom: 20,
            fontSize: 16
          }}>
          <div style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 10 }}>{title}</div>
          <div style={{ fontSize: 16 }}>{message}</div>
        </div>
      </div>
    </Dialog>
  );
};

export default MaterialProgressDialog;
