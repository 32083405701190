import React, { Component } from 'react';
import './index.css';
import OvVideoComponent from './OvVideo';
import data from '@src/components/constant';
import MicOff from '@mui/icons-material/MicOff';
import VideocamOff from '@mui/icons-material/VideocamOff';
import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeOff from '@mui/icons-material/VolumeOff';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import UserModel from '@src/models/user-model';
import Stanza from '@src/network/model/stanza';
import Partecipante from '@src/network/model/partecipante';

type Props = {
  user: UserModel;
  localUser: UserModel;
  identifier: string;
  datistanza: Stanza;
  data: Partecipante;
  figura: string;
  latencyUsers: Map<string, number>;
  closeCamera: (connectionId: string) => void;
  toogleSound?: (identifier: string) => void;
};

export default class StreamComponent extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.toggleSound = this.toggleSound.bind(this);
    this.handleStopIPCamera = this.handleStopIPCamera.bind(this);
  }

  handleChange(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
  }

  handleStopIPCamera(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    this.props.closeCamera(this.props.user.connectionId);
  }

  toggleSound() {
    if (this.props.toogleSound) {
      this.props.toogleSound(this.props.identifier);
    }
  }

  render() {
    const identifier = this.props.identifier;
    const user_data = this.props.data;
    const idModeratore = this.props.datistanza.idModeratore;
    const localUsername = this.props.localUser
      ? (this.props.localUser.data as Partecipante).username
      : '';
    const user_id = user_data.id;
    const latencyUsers = this.props.latencyUsers;

    let image;
    switch (latencyUsers.get(user_id)) {
      case 1:
        image = 'assets/signal/signal_1.png';
        break;
      case 2:
        image = 'assets/signal/signal_2.png';
        break;
      case 3:
        image = 'assets/signal/signal_3.png';
        break;
      case 4:
        image = 'assets/signal/signal_4.png';
        break;
      case 5:
        image = 'assets/signal/signal_5.png';
        break;
      default:
        image = 'assets/signal/signal_0.png';
        break;
    }

    return (
      <div className="OT_widget-container">
        <div>
          <div
            id={'name-' + identifier}
            style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <span
              className="nickname"
              style={{
                background:
                  'linear-gradient(90deg,' + data.primary + ' 0%,rgba(0, 212, 255, 0) 100%)'
              }}>
              {this.props.user.nickname.replace('&#39;', "'")}
            </span>
            <span className="figura">
              {this.props.figura !== undefined ? this.props.figura.replace('&#39;', "'") : ''}
            </span>
          </div>
          <div style={{ position: 'absolute', top: 10, left: 10, zIndex: 1200 }}>
            <img style={{ flex: 1, width: 30 }} alt={'Logo'} src={image} />
          </div>
        </div>
        {this.props.user !== undefined && this.props.user.streamManager !== undefined ? (
          <div className="streamComponent">
            <OvVideoComponent key={'name-' + identifier} user={this.props.user} />
            {this.props.user.nickname === 'IPCAM' && idModeratore === localUsername ? (
              <Button
                onClick={this.handleStopIPCamera}
                variant="contained"
                style={{ position: 'absolute', top: 10, left: 45 }}
                color="secondary">
                Chiudi
              </Button>
            ) : null}
            <div id="statusIcons">
              {!this.props.user.videoActive ? (
                <div id="camIcon">
                  <IconButton
                    id="statusButton"
                    style={{ display: 'block', background: data.primary, width: 40, height: 40 }}>
                    <VideocamOff />
                  </IconButton>
                </div>
              ) : null}

              {!this.props.user.audioActive ? (
                <div id="micIcon">
                  <IconButton
                    id="statusButton"
                    style={{ display: 'block', background: data.primary, width: 40, height: 40 }}>
                    <MicOff style={{ color: 'white' }} />
                  </IconButton>
                </div>
              ) : null}
            </div>
            <div>
              {this.props.user.type !== 'local' && (
                <IconButton
                  id="volumeButton"
                  onClick={this.toggleSound}
                  style={
                    this.props.user.nickname === 'IPCAM'
                      ? { display: 'none' }
                      : { display: 'block', background: data.primary, width: 40, height: 40 }
                  }>
                  {this.props.user.muted ? <VolumeOff color="secondary" /> : <VolumeUp />}
                </IconButton>
              )}
            </div>

            {!this.props.user.videoActive ? (
              <div className="videoOffContainer">
                <div className="userVideoOff" style={{ backgroundColor: data.primary, zIndex: 10 }}>
                  <p style={{ alignSelf: 'center' }}>
                    {this.props.data.cognome.charAt(0) + this.props.data.nome.charAt(0)}
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
