/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import List from '@mui/material/List';
import { Button, ToggleButtonGroup, ToggleButton, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import './index.scss';
import data from '@src/components/constant';
import MaterialDialog, { DialogButton } from '@src/components/base/material-dialog';
import MaterialProgressDialog from '@src/components/base/material-progress';
import ItemDoc from '@src/components/item-document';
import logger from '@src/utils/logger';
import { getEstensioni } from '@src/utils/estensioniAccettate';
import { uploadFile } from '@src/network/restAPI';
import Stanza from '@src/network/model/stanza';
import DocStanza from '@src/network/model/doc';
import QRCode from 'react-qr-code';
import ListIcon from '@mui/icons-material/List';
import GridViewIcon from '@mui/icons-material/GridView';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  drawer: {
    width: '100%',
    flexShrink: 0,
    backgroundColor: data.grey
  }
}));

type Props = {
  roomId: string;
  idPartecipante: string;
  docCondivisi: DocStanza[];
  onSendedFile?: () => void;
  datistanza: Stanza | undefined;
  showUpload: boolean;
  onDismiss?: () => void;
};

const MenuDocs = (props: Props) => {
  const classes = useStyles();
  const [file, setFile] = useState<File | undefined>(undefined);
  const [nomeFile, setNomeFile] = useState('');

  const [showDialog, setShowDialog] = useState(false);
  const [messageDialog, setMessageDialog] = useState('');
  const [typeDialog, setTypeDialog] = useState<'error' | 'success' | 'warning'>('success');
  const [buttonsDialog, setButtonsDialog] = useState<DialogButton[]>([]);
  const [messageProgress, setMessageProgress] = useState('');
  const [showProgressDialog, setShowProgressDialog] = useState(false);

  const [typeList, setTypeList] = useState<'list' | 'grid'>('list');

  const { docCondivisi, onSendedFile, datistanza, showUpload } = props;

  logger('MenuDocs', 'MODULO DOCS');

  /**
   * Nascondi progress
   */
  const nascondiProgressDialog = () => {
    setShowProgressDialog(false);
  };

  /**
   * Mostra Progress con messaggio
   */
  const mostraProgressDialog = (message: string) => {
    setMessageProgress(message);
    setShowProgressDialog(true);
  };

  /**
   * Nascondi Dialog
   */
  const nascondiDialog = () => {
    setShowDialog(false);
  };

  /**
   * Mostra Dialog
   * INPUT: messaggio
   */
  const mostraDialog = (
    message: string,
    type: 'error' | 'success' | 'warning',
    buttons = [
      {
        title: 'ok',
        onItemPress: function () {
          nascondiDialog();
        }
      }
    ]
  ) => {
    setMessageDialog(message);
    setTypeDialog(type);
    setShowDialog(true);
    if (buttons !== undefined) {
      setButtonsDialog(buttons);
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Do something with the files
    if (getEstensioni().includes(acceptedFiles[0].type)) {
      setFile(acceptedFiles[0]);
      setNomeFile(acceptedFiles[0].name);
    } else {
      logger('onDrop', 'Formato File non riconosciuto : ' + acceptedFiles[0].name);
      mostraDialog(
        'Formato File non riconosciuto. Il sistema accetta Formati Immagine (PNG, JPG) e Documenti PDF',
        'error'
      );
    }
  }, []);

  const executeUploadFile = async (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();

    mostraProgressDialog('Invio File in corso...');
    const response = await uploadFile(file);
    if (response.status === 200) {
      nascondiProgressDialog();
      if (onSendedFile) {
        onSendedFile();
      }
    } else {
      mostraDialog(response.object as string, 'error');
    }
  };

  const getPartecipante = (id: string) => {
    if (datistanza) {
      const partecipante = datistanza.partecipanti.filter((value) => {
        return value.id === id;
      });
      return partecipante[0];
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false
  });

  const loadInput = () => {
    if (
      navigator.userAgent.indexOf('APP_SMARTLEA') > -1 ||
      navigator.userAgent.indexOf('APP_CUREDOM') > -1 ||
      navigator.userAgent.indexOf('APP_SL_RN') > -1
    ) {
      return (
        <div className="drop-input">
          <input
            type="file"
            style={{
              position: 'absolute',
              top: '0',
              bottom: '0',
              left: '0',
              right: '0',
              opacity: '0',
              cursor: 'pointer',
              width: '100%'
            }}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              ev.preventDefault();
              if (ev.target.files !== null) {
                console.log(ev.target.files);
                // Do something with the files
                if (getEstensioni().includes(ev.target.files[0].type)) {
                  setFile(ev.target.files[0]);
                  setNomeFile(ev.target.files[0].name);
                } else {
                  logger('InputFile', 'Formato File non riconosciuto : ' + ev.target.files[0].name);
                  mostraDialog(
                    'Formato File non riconosciuto. Il sistema accetta Formati Immagine (PNG, JPG) e Documenti PDF',
                    'error'
                  );
                }
              }
            }}
          />
          <p style={{ padding: 10, textAlign: 'center', width: '100%' }}>
            {'Clicca qui per selezionare il file.'}
          </p>
        </div>
      );
    } else {
      return (
        <div {...getRootProps()} className="drop-input">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p
              style={{
                padding: 10,
                textAlign: 'center',
                width: '100%',
                color: data.primary
              }}>
              Lascia il file qui...
            </p>
          ) : (
            <p style={{ padding: 10, textAlign: 'center', width: '100%' }}>
              {'Trascina il file qui oppure clicca qui per selezionare il file.'}
            </p>
          )}
        </div>
      );
    }
  };

  return (
    <div className={classes.drawer} id="drawer" style={{ background: '#eeeeee' }}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          background: '#eeeeee'
        }}>
        <div className="containerDocs">
          <div className="uploadView" style={{ display: showUpload ? 'block' : 'none' }}>
            <span
              style={{
                paddingLeft: 10,
                paddingTop: 28,
                fontSize: 18,
                fontWeight: 'bold'
              }}>
              Documenti
            </span>
            {loadInput()}
            {file !== undefined ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ textAlign: 'center', width: '100%', fontSize: 12 }}>{nomeFile}</p>
                <Button
                  style={{ margin: 10, backgroundColor: data.primary }}
                  variant="contained"
                  color="primary"
                  onClick={(e) => executeUploadFile(e)}>
                  Invia
                </Button>
              </div>
            ) : null}
            <div className="box-qr-code">
              <span style={{ padding: 10 }}>
                Scansiona il qrcode per inviare documenti dal tuo smartphone
              </span>
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: 200, width: 200, marginBottom: 10 }}
                value={window.location.toString().replace('/session/', '/sharedrop/')}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>
          <div
            className={showUpload ? 'elenco-docs' : undefined}
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: window.matchMedia('(orientation: portrait)').matches ? 1 : 3
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: 20
              }}>
              <span
                style={{
                  paddingLeft: 10,
                  fontSize: 18,
                  fontWeight: 'bold',
                  display: 'flex',
                  flex: 1
                }}>
                Files
              </span>
              <ToggleButtonGroup
                color="primary"
                value={typeList}
                exclusive
                onChange={(event, value) => setTypeList(value)}>
                <ToggleButton value="list">
                  <ListIcon />
                </ToggleButton>
                <ToggleButton value="grid">
                  <GridViewIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            {typeList === 'list' ? (
              <List style={{ height: '100%', overflow: 'auto', marginTop: 10 }}>
                {docCondivisi.map((item, i) => (
                  <ItemDoc
                    typeList={typeList}
                    datiPartecipante={getPartecipante(item.idPartecipante)}
                    key={i}
                    nomeFile={item.nomeFile}
                    urlFile={item.urlFile}
                    mimetype={item.mimetype}
                    onDismiss={props.onDismiss}></ItemDoc>
                ))}
              </List>
            ) : (
              <Grid
                container
                spacing={3}
                style={{ height: '100%', overflow: 'auto', marginTop: 10 }}>
                {docCondivisi.map((item, i) => (
                  <Grid key={i} item xs={6} md={4} lg={2}>
                    <ItemDoc
                      typeList={typeList}
                      datiPartecipante={getPartecipante(item.idPartecipante)}
                      nomeFile={item.nomeFile}
                      urlFile={item.urlFile}
                      mimetype={item.mimetype}
                      onDismiss={props.onDismiss}></ItemDoc>
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
        </div>
      </div>
      <MaterialDialog
        visible={showDialog}
        message={messageDialog}
        type={typeDialog}
        buttons={buttonsDialog}></MaterialDialog>
      <MaterialProgressDialog
        visible={showProgressDialog}
        message={messageProgress}></MaterialProgressDialog>
    </div>
  );
};

export default MenuDocs;
