/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './index.css';
import UserModel from '@src/models/user-model';
import { Publisher, SignalEvent } from 'openvidu-browser';

type Props = {
  user: UserModel;
};

export default class OvVideoComponent extends Component<Props> {
  videoRef: React.RefObject<HTMLVideoElement>;

  constructor(props: Props) {
    super(props);
    this.videoRef = React.createRef();
  }

  async componentDidMount() {
    console.log('Utente:', this.props.user, 'Muted: ', this.props.user.muted);
    if (this.props && this.props.user.streamManager && this.videoRef.current !== null) {
      try {
        if (this.videoRef.current !== null) {
          this.props.user.streamManager.addVideoElement(this.videoRef.current);
          const video = this.videoRef.current;
          const isPlaying =
            video.currentTime > 0 &&
            !video.paused &&
            !video.ended &&
            video.readyState > video.HAVE_CURRENT_DATA;
          if (!isPlaying) {
            video.play();
          }
        }
      } catch (error) {
        console.log('OVVIdeo', error);
      }
    }

    if (
      this.props &&
      (this.props.user.streamManager as Publisher).session &&
      this.props.user &&
      !!this.videoRef
    ) {
      (this.props.user.streamManager as Publisher).session.on(
        'signal:userChanged',
        async (event: SignalEvent) => {
          const data = JSON.parse(event.data);
          if (data.isScreenShareActive !== undefined && this.videoRef.current !== null) {
            try {
              if (this.videoRef.current !== null) {
                this.props.user.streamManager.addVideoElement(this.videoRef.current);
                const video = this.videoRef.current;
                const isPlaying =
                  video.currentTime > 0 &&
                  !video.paused &&
                  !video.ended &&
                  video.readyState > video.HAVE_CURRENT_DATA;
                if (!isPlaying) {
                  video.play();
                }
              }
            } catch (error) {
              console.log('signal:userChanged', error);
            }
          }
        }
      );
    }
  }

  /*
  async componentDidUpdate(props: Props) {
    if (props && this.videoRef.current !== null) {
      try {
        if (this.videoRef.current !== null) {
          this.props.user.streamManager.addVideoElement(this.videoRef.current);
          /*const video = this.videoRef.current;
          const isPlaying =
            video.currentTime > 0 &&
            !video.paused &&
            !video.ended &&
            video.readyState > video.HAVE_CURRENT_DATA;
          if (!isPlaying) {
            video.play();
          }
        }
      } catch (error) {
        console.log('signal:userChanged', error);
      }
    }
  }*/

  render() {
    return (
      <video
        playsInline={true}
        autoPlay={true}
        id={'video-' + this.props.user.streamManager.stream.streamId}
        ref={this.videoRef}
        className={'video-' + this.props.user.streamManager.stream.streamId}
        muted={this.props.user.muted}
        style={this.props.user.nickname === 'IPCAM' ? { background: 'black' } : undefined}
      />
    );
  }
}
