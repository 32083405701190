/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from 'axios';
import { Agent } from 'https';
import logger from '../utils/logger';
import AccessoStanzaResponse from './model/output/accessoStanzaResponse';
import AccessoVideoStanzaResponse from './model/output/accessoVideoStanzaResponse';
import Stanza from './model/stanza';
import RecordStanza from './model/record';
import DocStanza from './model/doc';
import MeasureStanza from './model/measure';
import SendMeasureInput from './model/input/sendMeasureInput';
import SendWebMeasureInput from './model/input/sendWebMeasureInput';

export const urlBackEnd = `https://${window.location.hostname}`;
const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const access_token = window.sessionStorage.getItem('access_token');
    config.headers = {
      Authorization: `Bearer ${access_token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      console.log('401', 'eseguo refresh token');
      originalRequest._retry = true;
      const access_token = await captureRefreshToken();
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

export const captureRefreshToken = async () => {
  const method = 'captureRefreshToken';
  const accessToken = window.sessionStorage.getItem('access_token');
  const refreshToken = window.sessionStorage.getItem('refresh_token');
  console.log(accessToken, refreshToken);
  try {
    const response = await excecuteRefreshToken({
      accessToken,
      refreshToken
    });
    if (response.status === 200 && typeof response.object !== 'string') {
      const { jwt } = response.object;
      window.sessionStorage.setItem('access_token', jwt.accessToken);
      window.sessionStorage.setItem('refresh_token', jwt.refreshToken);
      return true;
    }
    throw new Error('Sessione Scaduta');
  } catch (error) {
    return responseError(method, error);
  }
};

export type ApiResponse<T> = {
  status: number;
  object: T | null;
};

const responseError = (method: string, obj: AxiosError) => {
  logger(method, JSON.stringify(obj));
  if (obj.response !== undefined) {
    return { status: obj.response.status, object: obj.response.data };
  } else {
    return { status: 500, object: '' };
  }
};

const ping = async (): Promise<ApiResponse<number>> => {
  const method = 'Ping';
  const started = new Date().getTime();
  try {
    const response = await axios.get(urlBackEnd, {
      headers: {
        'Content-Type': 'application/json'
      },
      httpsAgent: new Agent({ rejectUnauthorized: false })
    });
    if (response.status === 200) {
      const ended = new Date().getTime();
      const millisec = ended - started;
      return { status: 200, object: millisec };
    } else {
      return { status: response.status, object: response.data };
    }
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Controllo Accesso partecipante
 * @param {string} roomId roomid es. AV|1234
 * @param {string} username username partecipante
 */
const checkAccesso = async (): Promise<ApiResponse<string>> => {
  const method = 'CheckAccessoPartecipante';
  try {
    const response = await axiosApiInstance.post(
      `${urlBackEnd}/session/checkaccessopartecipante`,
      '',
      {
        headers: {
          'Content-Type': 'application/json'
        },
        httpsAgent: new Agent({ rejectUnauthorized: false })
      }
    );
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Chiamata REST accesso alla sessione
 * @param {string} token token
 */
const accessoStanza = async (
  token: string,
  sharedrop?: boolean
): Promise<ApiResponse<AccessoStanzaResponse | string>> => {
  const method = 'AccessoStanza';
  const body = {
    token,
    sharedrop
  };
  try {
    const response = await axios.post(urlBackEnd + '/stanza/accessostanza', JSON.stringify(body), {
      headers: {
        'Content-Type': 'application/json'
      },
      httpsAgent: new Agent({ rejectUnauthorized: false })
    });
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Chiamata REST accesso ai video
 * @param {string} token token
 */
const accessoVideoStanza = async (
  token: string
): Promise<ApiResponse<AccessoVideoStanzaResponse | string>> => {
  const method = 'AccessoVideoStanza';
  const body = {
    token
  };
  try {
    const response = await axios.post(urlBackEnd + '/stanza/accessovideo', JSON.stringify(body), {
      headers: {
        'Content-Type': 'application/json'
      },
      httpsAgent: new Agent({ rejectUnauthorized: false })
    });
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Chiamata REST per chiudere la stanza
 */
const closeStanza = async (): Promise<ApiResponse<string>> => {
  const method = 'CloseStanza';
  try {
    const response = await axiosApiInstance.post(urlBackEnd + '/session/closestanza', '', {
      headers: {
        'Content-Type': 'application/json'
      },
      httpsAgent: new Agent({ rejectUnauthorized: false })
    });
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Chiamata REST per ottenere dati stanza
 * @param {function} callback callback
 */
const getStanza = async (): Promise<ApiResponse<{ datiStanza: Stanza }>> => {
  const method = 'GetStanza';
  try {
    const response = await axiosApiInstance.post(urlBackEnd + '/session/getstanza', '', {
      headers: {
        'Content-Type': 'application/json'
      },
      httpsAgent: new Agent({ rejectUnauthorized: false })
    });
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Chiamata REST per ottenere elenco registrazioni
 * @param {string} roomId roomId
 */
const getRecords = async (roomId: string): Promise<ApiResponse<RecordStanza[]>> => {
  const method = 'GetRecords';
  const body = {
    roomId
  };
  logger(method, body);
  try {
    const response = await axiosApiInstance.post(
      urlBackEnd + '/session/video/get',
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json'
        },
        httpsAgent: new Agent({ rejectUnauthorized: false })
      }
    );
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Chiamata REST per ottenere elenco documenti condivisi
 */
const getDocs = async (): Promise<ApiResponse<DocStanza[]>> => {
  const method = 'GetDocs';
  try {
    const response = await axiosApiInstance.post(urlBackEnd + '/session/docs', '', {
      headers: {
        'Content-Type': 'application/json'
      },
      httpsAgent: new Agent({ rejectUnauthorized: false })
    });
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Chiamata REST per ottenere elenco misurazioni fatte
 */
const getMeasure = async (): Promise<ApiResponse<MeasureStanza[]>> => {
  const method = 'GetMeasure';
  try {
    const response = await axiosApiInstance.post(urlBackEnd + '/session/measure', '', {
      headers: {
        'Content-Type': 'application/json'
      },
      httpsAgent: new Agent({ rejectUnauthorized: false })
    });
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Chiamata REST per invio misurazione
 */
const insertMeasure = async (
  misurazione: SendMeasureInput
): Promise<ApiResponse<boolean | string>> => {
  const method = 'InsertMeasure';
  const body = {
    misurazione
  };
  logger(method, body);
  try {
    const response = await axiosApiInstance.post(
      urlBackEnd + '/session/insertmeasure',
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json'
        },
        httpsAgent: new Agent({ rejectUnauthorized: false })
      }
    );
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Funzione di invio misurazione manuale da browser
 */
const insertWebMeasure = async (
  datiMisurazione: SendWebMeasureInput
): Promise<ApiResponse<boolean | string>> => {
  const method = 'InsertWebMeasure';
  const { domain, idAssistito, idEvent, misurazione } = datiMisurazione;
  const body = {
    domain,
    idAssistito,
    idEvent,
    misurazione
  };
  logger(method, body);
  try {
    const response = await axiosApiInstance.post(
      urlBackEnd + '/session/insertwebmeasure',
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json'
        },
        httpsAgent: new Agent({ rejectUnauthorized: false })
      }
    );
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Chiamata REST per upload di un file
 */
const uploadFile = async (file: File): Promise<ApiResponse<DocStanza | string>> => {
  const method = 'UploadFile';
  const data = new FormData();
  data.append('file', file);
  try {
    const response = await axiosApiInstance.post(urlBackEnd + '/session/upload', data, {
      headers: {
        'Content-Type': 'application/json'
      },
      httpsAgent: new Agent({ rejectUnauthorized: false })
    });
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Chiamata per avviare la registrazione
 */
const startRecord = async (): Promise<ApiResponse<string>> => {
  const method = 'startRecord';
  const body = {
    outputMode: 'COMPOSED_QUICK_START'
  };
  logger(method, body);
  try {
    const response = await axiosApiInstance.post(
      urlBackEnd + '/session/startrecord',
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json'
        },
        httpsAgent: new Agent({ rejectUnauthorized: false })
      }
    );
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Chiamata per Fermare la Registrazione
 * @param {string} roomId roomId
 * @param {string} idPartecipante idPartecipante
 */
const stopRecord = async (): Promise<ApiResponse<string>> => {
  const method = 'stopRecord';
  try {
    const response = await axiosApiInstance.post(urlBackEnd + '/session/stoprecord', '', {
      headers: {
        'Content-Type': 'application/json'
      },
      httpsAgent: new Agent({ rejectUnauthorized: false })
    });
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Chiamata per avviare la camera
 */
const startIPCamera = async (
  urlIP: string,
  nomeCamera: string
): Promise<ApiResponse<{ id: string } | string>> => {
  const method = 'startIPCamera';
  const body = {
    urlIP,
    nomeCamera,
    adaptativeBitrate: false
  };
  logger(method, body);
  try {
    const response = await axiosApiInstance.post(
      urlBackEnd + '/session/startIPCamera',
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json'
        },
        httpsAgent: new Agent({ rejectUnauthorized: false })
      }
    );
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Chiamata per Fermare l'ipcam
 */
const stopIPCamera = async (idConnessione: string): Promise<ApiResponse<string>> => {
  const method = 'stopIPCamera';
  const body = {
    idConnessione
  };
  logger(method, body);
  try {
    const response = await axiosApiInstance.post(
      urlBackEnd + '/session/stopIPCamera',
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json'
        },
        httpsAgent: new Agent({ rejectUnauthorized: false })
      }
    );
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Chiamata per Uscire dalla stanza
 */
const esciStanza = async (streamid: string): Promise<ApiResponse<string>> => {
  const method = 'EsciStanza';
  const body = {
    streamid
  };
  try {
    const response = await axiosApiInstance.post(
      urlBackEnd + '/session/escistanza',
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json'
        },
        httpsAgent: new Agent({ rejectUnauthorized: false })
      }
    );
    window.sessionStorage.removeItem('access_token');
    window.sessionStorage.removeItem('refresh_token');
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Chiamata REST per avviare lo stetoscopio
 */
const stetoscopeON = async (): Promise<ApiResponse<string>> => {
  const method = 'stetoscopeON';

  try {
    const response = await axiosApiInstance.post(urlBackEnd + '/session/stetoscope/on', '', {
      headers: {
        'Content-Type': 'application/json'
      },
      httpsAgent: new Agent({ rejectUnauthorized: false })
    });
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Chiamata REST per fermare lo stetoscopio
 */
const stetoscopeOFF = async (): Promise<ApiResponse<string>> => {
  const method = 'stetoscopeOFF';
  try {
    const response = await axiosApiInstance.post(urlBackEnd + '/session/stetoscope/off', '', {
      headers: {
        'Content-Type': 'application/json'
      },
      httpsAgent: new Agent({ rejectUnauthorized: false })
    });
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

/**
 * Chiamata per refresh del token
 * @param body
 */
const excecuteRefreshToken = async (body: {
  accessToken: string;
  refreshToken: string;
}): Promise<
  ApiResponse<
    | {
        jwt: {
          accessToken: string;
          refreshToken: string;
        };
      }
    | string
  >
> => {
  const method = 'refreshToken';
  try {
    const response = await axios.post(urlBackEnd + '/session/refreshtoken', JSON.stringify(body), {
      headers: {
        Authorization: 'Bearer ' + body.refreshToken,
        'Content-Type': 'application/json'
      },
      httpsAgent: new Agent({ rejectUnauthorized: false })
    });
    return { status: response.status, object: response.data };
  } catch (error) {
    const obj = error as AxiosError;
    return responseError(method, obj);
  }
};

export {
  ping,
  checkAccesso,
  accessoStanza,
  accessoVideoStanza,
  closeStanza,
  getStanza,
  getRecords,
  getDocs,
  getMeasure,
  insertMeasure,
  insertWebMeasure,
  uploadFile,
  startRecord,
  stopRecord,
  startIPCamera,
  stopIPCamera,
  esciStanza,
  stetoscopeON,
  stetoscopeOFF
};
