/* eslint-disable @typescript-eslint/no-explicit-any */
import { ListItem, ListItemIcon } from '@mui/material';
import React from 'react';
import { urlBackEnd } from '../../network/restAPI';
import './index.css';
import Partecipante from '@src/network/model/partecipante';

type Props = {
  typeList: 'list' | 'grid';
  nomeFile: string;
  urlFile: string;
  datiPartecipante: Partecipante;
  mimetype: string;
  onDismiss?: () => void;
};

const ItemDoc = (props: Props) => {
  const { nomeFile, urlFile, datiPartecipante, mimetype, typeList } = props;

  const openURL = () => {
    if (urlFile !== undefined) {
      const _url = urlFile.replace('/public/', '/stanza/file/');
      if (
        navigator.userAgent.indexOf('APP_SMARTLEA') > -1 ||
        navigator.userAgent.indexOf('APP_CUREDOM') > -1 ||
        navigator.userAgent.indexOf('APP_SL_RN') > -1
      ) {
        if (navigator.userAgent.indexOf('GECKOVIEW') > -1) {
          const message = {
            type: 'visualizzaFile',
            response: {
              mimetype,
              urlFile: urlBackEnd + urlFile
            }
          };
          window.postMessage(message, '*');
        } else {
          if ((window as any).JSInterface) {
            (window as any).JSInterface.visualizzaFile(mimetype, urlBackEnd + _url);
            props.onDismiss();
          }
        }
      } else {
        const win = window.open(_url, '_blank');
        if (win !== null) {
          win.focus();
        }
      }
    }
  };

  return typeList === 'list' ? (
    <ListItem button onClick={openURL}>
      <ListItemIcon>
        <img
          alt="logo"
          style={{ width: 30, height: 30 }}
          src={
            mimetype.indexOf('image') > -1 ? 'assets/images/image.svg' : 'assets/images/pdf.svg'
          }></img>
      </ListItemIcon>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <span style={{ wordBreak: 'break-all', fontWeight: 'bold', fontSize: 16 }}>{nomeFile}</span>
        {datiPartecipante !== undefined ? (
          <span style={{ wordBreak: 'break-all', fontSize: 12 }}>
            {'Condiviso da ' + datiPartecipante.cognome + ' ' + datiPartecipante.nome}
          </span>
        ) : null}
      </div>
    </ListItem>
  ) : (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      onClick={openURL}>
      <ListItemIcon>
        <img
          alt="logo"
          style={{ width: 40, height: 40, marginBottom: 10 }}
          src={
            mimetype.indexOf('image') > -1 ? 'assets/images/image.svg' : 'assets/images/pdf.svg'
          }></img>
      </ListItemIcon>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', textAlign: 'center' }}>
        <span style={{ wordBreak: 'break-all', fontWeight: 'bold', fontSize: 16 }}>{nomeFile}</span>
        {datiPartecipante !== undefined ? (
          <span style={{ wordBreak: 'break-all', fontSize: 12 }}>
            {'Condiviso da ' + datiPartecipante.cognome + ' ' + datiPartecipante.nome}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default ItemDoc;
