/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import { Button, createTheme, Dialog, LinearProgress, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import data from '@src/components/constant';
import './index.scss';
import logger from '@src/utils/logger';
import { urlBackEnd } from '@src/network/restAPI';
import Stanza from '@src/network/model/stanza';
import DocStanza from '@src/network/model/doc';
import MeasureStanza from '@src/network/model/measure';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent'
  },

  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
    position: 'absolute',
    minWidth: 300,
    top: 20
  }
}));

type Props = {
  visible: boolean;
  type: string;
  message: string;
  content: DocStanza | MeasureStanza | string;
  progress: number;
  image: string;
  datistanza: Stanza;
  openSteto: (value: string) => void;
};

const Notification = (props: Props) => {
  const classes = useStyles();

  const {
    visible,
    type = 'doc',
    message = '',
    content,
    progress = 5,
    image = 'assets/images/notify.svg',
    datistanza,
    openSteto
  } = props;

  const MIN = 0;
  const MAX = 5;
  const normalise = (value: number) => ((value - MIN) * 100) / (MAX - MIN);

  const getPartecipante = (item: string) => {
    logger('getPartecipante', datistanza != null ? datistanza.partecipanti : '');
    if (datistanza != null) {
      const partecipante = datistanza.partecipanti.filter((value) => {
        return value.id === item;
      });
      if (partecipante[0] !== undefined) {
        return partecipante[0].cognome + ' ' + partecipante[0].nome;
      }
    }
  };

  /**
   * Apertura File Condiviso
   */
  const visualizzaDocumento = () => {
    if ((content as DocStanza).urlFile !== undefined) {
      const _url = (content as DocStanza).urlFile.replace('/public/', '/stanza/file/');
      if (
        navigator.userAgent.indexOf('APP_SMARTLEA') > -1 ||
        navigator.userAgent.indexOf('APP_CUREDOM') > -1 ||
        navigator.userAgent.indexOf('APP_SL_RN') > -1
      ) {
        if (navigator.userAgent.indexOf('GECKOVIEW') > -1) {
          const _message = {
            type: 'visualizzaFile',
            response: {
              mimetype: (content as DocStanza).mimetype,
              urlFile: urlBackEnd + _url
            }
          };
          window.postMessage(_message, '*');
        } else {
          if ((window as any).JSInterface) {
            (window as any).JSInterface.visualizzaFile(
              (content as DocStanza).mimetype,
              urlBackEnd + _url
            );
          }
        }
      } else {
        const win = window.open(_url, '_blank');
        if (win !== null) {
          win.focus();
        }
      }
    }
  };

  /**
   * Apertura File PDF Esami Diagnostici (ECG)
   */
  const apriDocumento = (mimetype: string, urlFile: string) => {
    if (urlFile !== undefined) {
      const _url = urlFile.replace('/public/', '/stanza/file/');
      if (
        navigator.userAgent.indexOf('APP_SMARTLEA') > -1 ||
        navigator.userAgent.indexOf('APP_CUREDOM') > -1 ||
        navigator.userAgent.indexOf('APP_SL_RN') > -1
      ) {
        if (navigator.userAgent.indexOf('GECKOVIEW') > -1) {
          const _message = {
            type: 'visualizzaFile',
            response: {
              mimetype,
              _url
            }
          };
          window.postMessage(_message, '*');
        } else {
          if ((window as any).JSInterface) {
            (window as any).JSInterface.visualizzaFile(mimetype, _url);
          }
        }
      } else {
        const win = window.open(_url, '_blank');
        if (win !== null) {
          win.focus();
        }
      }
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: data.primary
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={visible}
        BackdropProps={{
          classes: {
            root: classes.root
          }
        }}
        PaperProps={{
          classes: {
            root: classes.paper
          }
        }}>
        <div className="dialog_content">
          {type === 'file' ? (
            <div style={{ padding: 5 }}>
              <span style={{ fontSize: 14, padding: 5, fontWeight: 'bold' }}>
                {getPartecipante((content as DocStanza).idPartecipante) + ': '}
              </span>
              <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                <div
                  style={{
                    alignSelf: 'center',
                    flex: 1,
                    display: 'inline-flex'
                  }}>
                  <img
                    style={{ width: 30, marginLeft: 5, marginRight: 5, height: 30 }}
                    alt="pdf"
                    src={
                      (content as DocStanza).mimetype.indexOf('image') > -1
                        ? 'assets/images/image.svg'
                        : 'assets/images/pdf.svg'
                    }
                  />
                  <div style={{ alignSelf: 'center' }}>
                    <span style={{ wordBreak: 'break-all', fontSize: 12 }}>
                      {(content as DocStanza).nomeFile}
                    </span>
                  </div>
                </div>
                <div>
                  <Button
                    style={{ margin: 10, backgroundColor: data.primary }}
                    variant="contained"
                    color="primary"
                    onClick={() => visualizzaDocumento()}>
                    Apri
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
          {type === 'measure' ? (
            <div style={{ padding: 5 }}>
              <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                <div
                  style={{
                    alignSelf: 'center',
                    flex: 1,
                    display: 'inline-flex'
                  }}>
                  <img
                    style={{ width: 60, marginLeft: 10, marginRight: 10, height: 60 }}
                    alt={'assets/device/ic_' + (content as MeasureStanza).device + '.png'}
                    src={'assets/device/ic_' + (content as MeasureStanza).device + '.png'}
                  />
                  {(content as MeasureStanza).device === 'ecg' ||
                  (content as MeasureStanza).device === 'spirometro' ||
                  (content as MeasureStanza).device === 'stetoscopio' ? (
                    <div
                      style={{
                        alignSelf: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        width: 'calc(100% / ' + (content as MeasureStanza).misurazioni.length + ')'
                      }}>
                      {(content as MeasureStanza).misurazioni.map((item, i) => (
                        <div
                          key={i}
                          style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row'
                          }}>
                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'column',
                              justifyContent: 'center'
                            }}>
                            <span
                              style={{
                                wordBreak: 'break-all',
                                fontSize: 18,
                                fontWeight: 'bold',
                                justifyContent: 'center'
                              }}>
                              {item.key}
                            </span>
                          </div>
                          {item.key !== 'VC' ? (
                            <div>
                              <Button
                                style={{
                                  margin: 10,
                                  backgroundColor: data.primary
                                }}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  if ((content as MeasureStanza).device === 'stetoscopio') {
                                    openSteto(item.value);
                                  } else {
                                    apriDocumento('application/pdf', item.value);
                                  }
                                }}>
                                Apri
                              </Button>
                            </div>
                          ) : (
                            <div style={{ display: 'flex', flex: 1 }}>
                              <span
                                style={{
                                  wordBreak: 'break-all',
                                  fontSize: 16
                                }}>
                                {item.value}
                              </span>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div
                      style={{
                        alignSelf: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%'
                      }}>
                      {(content as MeasureStanza).misurazioni.map((item) => (
                        <div
                          key={uuidv4()}
                          style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row'
                          }}>
                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'column'
                            }}>
                            <span
                              style={{
                                wordBreak: 'break-all',
                                fontSize: 18,
                                fontWeight: 'bold'
                              }}>
                              {item.key}
                            </span>
                            <span style={{ wordBreak: 'break-all', fontSize: 16 }}>
                              {item.value} {item.unit}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}
          {type === 'message' ? (
            <div style={{ padding: 5 }}>
              <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                <div
                  style={{
                    alignSelf: 'center',
                    flex: 1,
                    display: 'inline-flex'
                  }}>
                  <img
                    style={{
                      width: 30,
                      marginLeft: 10,
                      marginRight: 10,
                      padding: 10,
                      height: 30
                    }}
                    alt={'notify'}
                    src={image}
                  />
                  <div style={{ alignSelf: 'center' }}>
                    <span style={{ wordBreak: 'break-all', fontSize: 14 }}>{message}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {type === 'static' ? (
            <div style={{ padding: 5 }}>
              <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                <div
                  style={{
                    alignSelf: 'center',
                    flex: 1,
                    display: 'inline-flex'
                  }}>
                  <img
                    style={{
                      width: 30,
                      marginLeft: 10,
                      marginRight: 10,
                      padding: 10,
                      height: 30
                    }}
                    alt={'notify'}
                    src={image}
                  />
                  <div style={{ alignSelf: 'center' }}>
                    <span style={{ wordBreak: 'break-all', fontSize: 14, fontWeight: 'bold' }}>
                      {message}
                    </span>
                    <br></br>
                    <span style={{ wordBreak: 'break-word', fontSize: 13 }}>
                      {content as string}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {visible === true ? (
            <audio
              src={'assets/sound/notifica.mp3'}
              controls
              autoPlay
              style={{ display: 'none' }}
            />
          ) : null}
          {progress !== -1 ? (
            <LinearProgress variant="determinate" value={normalise(progress)} color="primary" />
          ) : null}
        </div>
      </Dialog>
    </ThemeProvider>
  );
};

export default Notification;
