/* eslint-disable @typescript-eslint/ban-types */
import './index.scss';
import AbstractScreen from '@src/view/abstractscreen';
import { RouteComponentProps } from 'react-router';
import { accessoStanza, uploadFile, checkAccesso } from '@src/network/restAPI';
import AccessoStanzaResponse from '@src/network/model/output/accessoStanzaResponse';
import React from 'react';
import { Card, CardContent, Typography, Button } from '@mui/material';
import Partecipante from '@src/network/model/partecipante';
import { getEstensioni } from '@src/utils/estensioniAccettate';
import logger from '@src/utils/logger';
import data from '@src/components/constant';
import { OpenVidu, Session, SignalEvent } from 'openvidu-browser';

type Props = RouteComponentProps<{ token: string }>;

type State = {
  partecipante: Partecipante;
  file: File | undefined;
  nomeFile: string | undefined;
  session: Session | undefined;
  tokenOV: string | undefined;
};

class ShareDropScreen extends AbstractScreen<Props, State> {
  token: string;
  OV: OpenVidu;

  constructor(props: Props) {
    super(props);

    this.state = {
      ...this.state,
      file: undefined,
      nomeFile: undefined,
      session: undefined,
      tokenOV: undefined
    };

    this.executeAccessoStanza = this.executeAccessoStanza.bind(this);
    this.executeUploadFile = this.executeUploadFile.bind(this);
    this.goBack = this.goBack.bind(this);
    this.exitDalDrop = this.exitDalDrop.bind(this);
  }

  async componentDidMount() {
    document.title = 'Share Drop';
    console.log('token', this.props.match);

    //Catturo il token dal url query
    this.token = this.props.match.params.token;
    if (this.token !== undefined && this.token !== '' && this.token !== 'undefined') {
      //Chiamare Server API per decodifica token accesso
      this.executeAccessoStanza();
    } else {
      window.location.href = '/';
    }
  }

  async executeAccessoStanza() {
    await this.mostraProgressDialog('Controllo Partecipante in corso...');
    const response = await accessoStanza(this.token, true);
    if (response.object === null) {
      return;
    }

    if (response.status !== 200 && typeof response.object === 'string') {
      await this.nascondiProgressDialog();
      await this.mostraDialog(response.object, 'error', [
        {
          title: 'Esci',
          onItemPress: this.goBack.bind(this)
        }
      ]);
      return;
    }

    const { partecipante, idSessione, jwt, token } = response.object as AccessoStanzaResponse;

    const response2 = await checkAccesso();

    if (response2.object === 'Nessun Partecipante in stanza') {
      await this.nascondiProgressDialog();
      await this.mostraDialog('Nessun Partecipante in stanza', 'error', [
        {
          title: 'Esci',
          onItemPress: this.goBack.bind(this)
        }
      ]);
      return;
    }

    //set JWT token to local
    window.sessionStorage.setItem('access_token', jwt.accessToken);
    window.sessionStorage.setItem('refresh_token', jwt.refreshToken);

    await this.setStateAsync({
      tokenOV: token,
      myUserName: `${partecipante.cognome.replace('&#39;', "'")} ${partecipante.nome.replace(
        '&#39;',
        "'"
      )}`,
      myLocalUserName: partecipante.username,
      mySessionId: idSessione,
      partecipante
    });

    this.OV = new OpenVidu();
    this.OV.enableProdMode();
    await this.setStateAsync({
      session: this.OV.initSession()
    });

    await this.nascondiProgressDialog();

    await this.state.session.connect(this.state.tokenOV);

    this.state.session.on('signal', async (event) => {
      let signalData: { streamid: string; id: string };
      switch ((event as SignalEvent).type) {
        case 'signal:closedRoom':
          console.log(event);
          break;
        case 'signal:userExit':
          console.log(event);
          if ((event as SignalEvent).data) {
            signalData = JSON.parse((event as SignalEvent).data as string);
            console.log(signalData, this.state.partecipante.id);
            if (signalData.id === this.state.partecipante.id) {
              this.exitDalDrop();
            }
            break;
          }
      }
    });
  }

  async executeUploadFile(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    ev.preventDefault();

    this.mostraProgressDialog('Invio File in corso...');
    const response = await uploadFile(this.state.file);
    if (response.status === 200) {
      this.setStateAsync({
        file: undefined,
        nomeFile: undefined
      });
      this.nascondiProgressDialog();
    } else {
      this.mostraDialog(response.object as string, 'error');
    }
  }

  async exitDalDrop() {
    window.location.replace('/');
  }

  async goBack() {
    console.log('goBack');
    window.history.back();
  }

  async componentWillUnmount() {
    try {
      if (this.OV) {
        this.OV.closeWs();
        await this.setStateAsync({
          session: undefined
        });
        this.OV = undefined;
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div className="container-share-drop">
        {this.state.partecipante ? (
          <Card className="card-drop">
            <CardContent
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <Card variant="outlined" style={{ width: '90%', textAlign: 'center' }}>
                <h2>Partecipante</h2>
                <p>{this.state.partecipante.cognome + ' ' + this.state.partecipante.nome}</p>
              </Card>
              <img
                style={{ height: 200, width: '100%', objectFit: 'contain', marginTop: 10 }}
                src="assets/images/share_drop.png"
              />
              <Typography
                style={{ textAlign: 'center', marginBottom: 20 }}
                gutterBottom
                variant="h6"
                component="div">
                Invia qui i tuoi documenti nella Sessione Video
              </Typography>

              <div className="drop-input">
                <input
                  type="file"
                  style={{
                    position: 'absolute',
                    top: '0',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    opacity: '0',
                    cursor: 'pointer'
                  }}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    ev.preventDefault();
                    if (ev.target.files !== null) {
                      console.log(ev.target.files);
                      // Do something with the files
                      if (getEstensioni().includes(ev.target.files[0].type)) {
                        this.setStateAsync({
                          file: ev.target.files[0],
                          nomeFile: ev.target.files[0].name
                        });
                      } else {
                        logger(
                          'InputFile',
                          'Formato File non riconosciuto : ' + ev.target.files[0].name
                        );
                        this.mostraDialog(
                          'Formato File non riconosciuto. Il sistema accetta Formati Immagine (PNG, JPG) e Documenti PDF',
                          'error'
                        );
                      }
                    }
                  }}
                />
                <p style={{ padding: 10, textAlign: 'center', width: '100%' }}>
                  {'Clicca qui per selezionare il file.'}
                </p>
              </div>
              {this.state.file !== undefined ? (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <p style={{ textAlign: 'center', width: '100%', fontSize: 12 }}>
                    {this.state.nomeFile}
                  </p>
                  <Button
                    fullWidth={true}
                    style={{ backgroundColor: data.primary }}
                    variant="contained"
                    color="primary"
                    onClick={(e) => this.executeUploadFile(e)}>
                    Invia
                  </Button>
                </div>
              ) : null}
            </CardContent>
          </Card>
        ) : null}
        {super.render()}
      </div>
    );
  }
}

export default ShareDropScreen;
