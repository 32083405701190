/* eslint-disable @typescript-eslint/no-explicit-any */
export const getAudioBuffer = async (path: string): Promise<AudioBuffer | null> => {
  console.log('getAudioBuffer', path);
  const audioCtx: AudioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
  try {
    const response = await fetch(path);
    const audioData = await response.arrayBuffer();
    return new Promise((resolve) => {
      try {
        audioCtx
          .decodeAudioData(audioData, (buffer) => {
            return resolve(buffer);
          })
          .catch((error) => console.log(error));
      } catch (error) {
        return null;
      }
    });
  } catch (error) {
    return null;
  }
};
