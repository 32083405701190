import React from 'react';
import './notfound404.css';
import { Button } from '@mui/material';

const NotFound404 = () => {
  return (
    <div className="container_404">
      <img
        style={{ width: '50vw', marginLeft: 5, marginRight: 5, height: 300 }}
        alt="pdf"
        src={'assets/images/404.svg'}
      />
      <h3>Pagina non trovata</h3>
      <Button
        style={{
          padding: 10,
          fontSize: 16
        }}
        size="small"
        variant="contained"
        onClick={() => window.history.back()}>
        Indietro
      </Button>
    </div>
  );
};

export default NotFound404;
