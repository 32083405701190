import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import data from '@src/components/constant';
import Waveform from 'waveform-react';
import { getAudioBuffer } from '@src/components/utils';
import './styles.css';
import AudioPlayer from 'react-h5-audio-player/lib/index';
import 'react-h5-audio-player/lib/styles.css';
import 'regenerator-runtime/runtime';

type Props = {
  onClose: () => void;
  open: boolean;
  urlPath: string;
};

const AudioStetoPlayer = (props: Props) => {
  const { onClose, open, urlPath } = props;

  const [buffer, setBuffer] = useState<AudioBuffer | null>(null);
  const [, setBufferLoaded] = useState<boolean>(false);
  const [positionWave, setPositionWave] = useState<number>(0);
  const player = useRef(null);

  useMemo(() => {
    if (urlPath != undefined) {
      getAudioBuffer(`${urlPath}`).then((audioBuffer) => {
        if (audioBuffer !== undefined) {
          setBuffer(audioBuffer);
          setBufferLoaded(true);
        }
      });
    }
  }, [urlPath]);

  const handleClose = () => {
    setBuffer(null);
    onClose();
  };

  return (
    <Dialog open={open} maxWidth={'lg'}>
      <DialogTitle>Audio Player</DialogTitle>
      <DialogContent>
        <div>
          {/*bufferLoaded ? (
     
   ) : (
     <div style={{ display: 'flex', flex: 1, alignSelf: 'center' }}>
       <CircularProgress
         style={{
           margin: 20,
           height: 30,
           width: 30,
           color: data.primary,
         }}
       />
     </div>
   )*/}
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <div style={{ position: 'relative', margin: 15 }}>
              <Waveform
                // Audio buffer
                buffer={buffer != null ? buffer : null}
                // waveform height
                height={150}
                markerStyle={{
                  // Position marker color
                  color: data.primary,
                  // Position marker width (in pixels)
                  width: 1
                }}
                // Wave plot type (line or bar)
                plot="line"
                // Marker position on waveform (0 - 1)
                position={positionWave}
                // redraw waveform on window size change (default: true)
                responsive={true}
                // Show position marker
                showPosition={true}
                waveStyle={{
                  // animate waveform on draw (default: true)
                  animate: true,
                  // waveform color
                  color: '#000',
                  // width of each rendered point (min: 1, max: 10)
                  pointWidth: 1
                }}
                // waveform width
                width={400}
              />
            </div>
            <AudioPlayer
              ref={player}
              src={`${urlPath}`}
              onPlay={() => console.log('onPlay')}
              listenInterval={500}
              showSkipControls={false}
              customAdditionalControls={[]}
              onListen={(e) => {
                if (e.type === 'timeupdate' && e.target !== null) {
                  const { currentTime, duration } = e.target as HTMLMediaElement;
                  setPositionWave(currentTime / duration);
                }
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AudioStetoPlayer;
