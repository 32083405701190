/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React from 'react';
import './index.scss';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import DescriptionIcon from '@mui/icons-material/Description';
import Mic from '@mui/icons-material/Mic';
import CallEnd from '@mui/icons-material/CallEnd';
import MicOff from '@mui/icons-material/MicOff';
import Videocam from '@mui/icons-material/Videocam';
import VideocamOff from '@mui/icons-material/VideocamOff';
import ScreenShare from '@mui/icons-material/ScreenShare';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StopScreenShare from '@mui/icons-material/StopScreenShare';
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import MonitorHeart from '@mui/icons-material/MonitorHeart';
import {
  Badge,
  Drawer,
  Typography,
  Popover,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button
} from '@mui/material';
import MenuUtente from '../menu-utente';
import MenuTelemedicina from '../menu-telemedicina';
import MenuDocs from '../menu-docs';
import getMobileOperatingSystem from '@src/utils/checkOS';
import UserModel from '@src/models/user-model';
import Partecipante from '@src/network/model/partecipante';
import DocStanza from '@src/network/model/doc';
import Stanza from '@src/network/model/stanza';
import MeasureStanza from '@src/network/model/measure';
import AbstractScreen from '@src/view/abstractscreen';
import version from '@src/../package.json';

type Props = {
  micStatusChanged: () => void;
  camStatusChanged: () => void;
  screenShare: () => void;
  stopScreenShare: () => void;
  toggleFullscreen: () => void;
  leaveSession: () => void;
  openAudioPlayer: (url: string) => void;
  startRecording: () => void;
  stopRecording: () => void;
  closeSession: () => void;
  onPressDevice: (device: string) => void;
  switchCamera: () => void;
  exitSession: () => void;

  user: UserModel;
  remotes: UserModel[];
  partecipante: Partecipante | undefined;
  docCondivisi: DocStanza[];
  datistanza: Stanza;
  misurazioniDevice: MeasureStanza[];
  recAttiva: string;
  cams: number;
  isFrontCamera: boolean;
  timeValues: string;
  ipWorkStation: string;
  cameraAttiva: boolean;
};

type State = {
  width: number;
  fullscreen: boolean;
  menuUtenteOpened: boolean;
  menuTelemedicinaOpened: boolean;
  menuEsamiOpened: boolean;
  openMenuMobile: boolean;
  containerEl: Element | null;
};

export default class ToolbarComponent extends AbstractScreen<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      width: window.innerWidth,
      fullscreen: false,
      menuUtenteOpened: false,
      menuTelemedicinaOpened: false,
      menuEsamiOpened: false,
      openMenuMobile: false,
      containerEl: null
    };
    this.camStatusChanged = this.camStatusChanged.bind(this);
    this.micStatusChanged = this.micStatusChanged.bind(this);
    this.screenShare = this.screenShare.bind(this);
    this.stopScreenShare = this.stopScreenShare.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.leaveSession = this.leaveSession.bind(this);

    this.startRecording = this.startRecording.bind(this);
    this.stopRecording = this.stopRecording.bind(this);
    this.exitSession = this.exitSession.bind(this);
    this.closeSession = this.closeSession.bind(this);

    this.onSendedFile = this.onSendedFile.bind(this);
    this.onPressDevice = this.onPressDevice.bind(this);
    this.switchCamera = this.switchCamera.bind(this);
    this.openAudioPlayer = this.openAudioPlayer.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.toogleOpenMenu = this.toogleOpenMenu.bind(this);
  }

  micStatusChanged() {
    this.props.micStatusChanged();
  }

  camStatusChanged() {
    this.props.camStatusChanged();
  }

  screenShare() {
    this.props.screenShare();
  }

  stopScreenShare() {
    this.props.stopScreenShare();
  }

  toggleFullscreen() {
    this.props.toggleFullscreen();
  }

  toogleOpenMenu(e: any) {
    this.setStateAsync({
      menuTelemedicinaOpened: false,
      menuEsamiOpened: false,
      openMenuMobile: !this.state.openMenuMobile,
      containerEl: e.currentTarget
    });
  }

  leaveSession() {
    this.props.leaveSession();
  }

  async openAudioPlayer(urlAudio: string) {
    await this.setStateAsync({ menuTelemedicinaOpened: false });
    this.props.openAudioPlayer(urlAudio);
  }

  async onSendedFile() {
    await this.setStateAsync({ menuEsamiOpened: !this.state.menuEsamiOpened });
  }

  async startRecording() {
    await this.setStateAsync({ menuUtenteOpened: !this.state.menuUtenteOpened });
    this.props.startRecording();
  }

  async stopRecording() {
    await this.setStateAsync({ menuUtenteOpened: !this.state.menuUtenteOpened });
    this.props.stopRecording();
  }

  async exitSession() {
    await this.setStateAsync({ menuUtenteOpened: !this.state.menuUtenteOpened });
    this.props.exitSession();
  }

  async closeSession() {
    await this.setStateAsync({ menuUtenteOpened: !this.state.menuUtenteOpened });
    this.props.closeSession();
  }

  async onPressDevice(device: string) {
    await this.setStateAsync({ menuTelemedicinaOpened: false });
    this.props.onPressDevice(device);
  }

  switchCamera() {
    this.props.switchCamera();
  }

  handleWindowSizeChange() {
    this.setStateAsync({
      width: window.innerWidth
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  render() {
    const localUser = this.props.user;
    const remotes = this.props.remotes;
    const partecipante = this.props.partecipante;
    const docCondivisi = this.props.docCondivisi;
    const datistanza = this.props.datistanza;
    const misurazioniDevice = this.props.misurazioniDevice;
    const recAttiva = this.props.recAttiva;
    //const cams = this.props.cams;
    //const isFrontCamera = this.props.isFrontCamera;
    const timeValues = this.props.timeValues;
    const cameraAttiva = this.props.cameraAttiva;

    const showDevices =
      datistanza !== undefined &&
      partecipante !== undefined &&
      datistanza.idGestoreDispositivi === partecipante.username
        ? true
        : false;

    const isRemoteScreenSharing =
      (remotes !== undefined && remotes.length !== 0
        ? remotes.some((user) => user.screenShareActive)
        : false) || (localUser !== undefined ? localUser.screenShareActive : false);

    return (
      <AppBar className="toolbar" id="header" style={{ top: 'auto', bottom: 0 }} elevation={0}>
        <Toolbar className="toolbar">
          {partecipante && datistanza && partecipante.username === datistanza.idModeratore ? (
            <div className="buttonsLeft">
              <Tooltip title={'Menu Operazioni'}>
                <IconButton
                  color="secondary"
                  className="navButton"
                  id="navMenuButton"
                  style={{ paddingLeft: 10 }}
                  onClick={async () =>
                    await this.setStateAsync({
                      menuUtenteOpened: !this.state.menuUtenteOpened
                    })
                  }>
                  <MenuIcon style={{ width: 50 }}></MenuIcon>
                </IconButton>
              </Tooltip>

              <Drawer
                anchor="left"
                open={this.state.menuUtenteOpened}
                onClose={async () =>
                  await this.setStateAsync({
                    menuUtenteOpened: false
                  })
                }>
                <MenuUtente
                  datistanza={datistanza}
                  partecipante={partecipante}
                  recAttiva={recAttiva}
                  startRecording={this.startRecording}
                  stopRecording={this.stopRecording}
                  exitSession={this.exitSession}
                  closeSession={this.closeSession}></MenuUtente>
              </Drawer>
            </div>
          ) : (
            <div className="buttonsLeft">
              <div className="version" style={{ bottom: 8 }}>
                v. {version.version} del {version.date_version}
              </div>
            </div>
          )}
          {localUser !== undefined ? (
            <div className="buttonsContent">
              {recAttiva === 'true' ? (
                <div className="navButtonRec">
                  <img className="img_logo_rec" alt="reclogo" src={'assets/images/rec.svg'}></img>
                  <Typography id="text_rec" variant="body2">
                    {timeValues}
                  </Typography>
                </div>
              ) : null}
              <div>
                <Tooltip
                  title={
                    localUser !== undefined && localUser.audioActive
                      ? 'Disattiva Microfono'
                      : 'Attiva Microfono'
                  }>
                  <IconButton
                    color="secondary"
                    className="navButton"
                    id="navMicButton"
                    onClick={this.micStatusChanged}>
                    {localUser !== undefined && localUser.audioActive ? (
                      <Mic color="secondary" />
                    ) : (
                      <MicOff color="primary" />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
              <div>
                <Tooltip
                  title={
                    localUser !== undefined && localUser.videoActive
                      ? 'Disattiva Video'
                      : 'Attiva Video'
                  }>
                  <IconButton
                    color="secondary"
                    className="navButton"
                    id="navCamButton"
                    onClick={this.camStatusChanged}>
                    {localUser !== undefined && localUser.videoActive ? (
                      <Videocam color="secondary" />
                    ) : (
                      <VideocamOff color="primary" />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
              {getMobileOperatingSystem() === 'PC' ? (
                <div>
                  {localUser !== undefined && isRemoteScreenSharing ? null : (
                    <div className="navButton">
                      <Tooltip title="Attiva Condivisione Schermo">
                        <IconButton
                          color="secondary"
                          className="navButton"
                          onClick={this.screenShare}>
                          <ScreenShare color="secondary" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}

                  {localUser !== undefined && localUser.screenShareActive && (
                    <div className="navButton">
                      <Tooltip title="Disattiva Condivisione Schermo">
                        <IconButton
                          onClick={this.stopScreenShare}
                          color="primary"
                          className="navButton">
                          <StopScreenShare color="primary" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </div>
              ) : null}

              {/*cams !== undefined && cams === 2 ? (
                <div>
                  <div className="navButton">
                    <Tooltip title="Cambia Camera">
                      <IconButton
                        onClick={this.switchCamera}
                        id="navScreenButton"
                        className="navButton">
                        {isFrontCamera === true ? (
                          <SwitchCameraIcon color="secondary" />
                        ) : (
                          <SwitchCameraIcon color="primary" />
                        )}
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              ) : null*/}

              {partecipante.username !== datistanza.idModeratore ? (
                <div className="exit_button">
                  <Tooltip title={'Esci Stanza'}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={this.leaveSession}>
                      <CallEnd style={{ color: 'white' }} />
                    </Button>
                  </Tooltip>
                </div>
              ) : null}

              <Drawer
                anchor={window.matchMedia('(max-width: 700px)').matches ? 'top' : 'right'}
                PaperProps={{
                  sx: {
                    backgroundColor: '#eeeeee'
                  }
                }}
                open={this.state.menuTelemedicinaOpened}
                onClose={async () =>
                  await this.setStateAsync({
                    menuTelemedicinaOpened: false
                  })
                }>
                <MenuTelemedicina
                  username={partecipante !== undefined ? partecipante.username : ''}
                  misurazioniDevice={misurazioniDevice}
                  showDevices={showDevices}
                  cameraAttiva={cameraAttiva}
                  onPressDevice={(device) => this.onPressDevice(device)}
                  openAudio={(urlAudio) => {
                    this.openAudioPlayer(urlAudio);
                  }}
                />
              </Drawer>

              <Drawer
                anchor="top"
                open={this.state.menuEsamiOpened}
                onClose={async () =>
                  await this.setStateAsync({
                    menuEsamiOpened: false
                  })
                }>
                <MenuDocs
                  roomId={partecipante !== undefined ? partecipante.roomId : ''}
                  idPartecipante={partecipante !== undefined ? partecipante.id : ''}
                  datistanza={datistanza}
                  docCondivisi={docCondivisi}
                  showUpload={true}
                  onSendedFile={this.onSendedFile}
                  onDismiss={async () =>
                    await this.setStateAsync({
                      menuEsamiOpened: false
                    })
                  }></MenuDocs>
              </Drawer>

              {this.state.width > 767 ? (
                <div className="buttonsEngineRight" style={{ position: 'absolute' }}>
                  <div>
                    <Tooltip title={'Documenti'}>
                      <IconButton
                        color="inherit"
                        className="navButton"
                        id="rightButton"
                        onClick={async () =>
                          await this.setStateAsync({
                            menuEsamiOpened: !this.state.menuEsamiOpened
                          })
                        }>
                        <Badge
                          badgeContent={docCondivisi !== undefined ? docCondivisi.length : 0}
                          color="primary"
                          id="badge">
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              flex: 1,
                              alignItems: 'center'
                            }}>
                            <DescriptionIcon
                              color="secondary"
                              style={{ width: '100%' }}></DescriptionIcon>
                          </div>
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </div>

                  <div style={{ width: 60 }}>
                    <Tooltip title={'Device e Misurazioni'}>
                      <IconButton
                        className="navButton"
                        id="rightButton"
                        onClick={async () =>
                          await this.setStateAsync({
                            menuTelemedicinaOpened: !this.state.menuTelemedicinaOpened
                          })
                        }>
                        <Badge
                          badgeContent={
                            misurazioniDevice !== undefined ? misurazioniDevice.length : 0
                          }
                          color="primary"
                          id="badge">
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              flex: 1,
                              alignItems: 'center'
                            }}>
                            <MonitorHeart
                              style={{ width: '100%' }}
                              color="secondary"></MonitorHeart>
                          </div>
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <div className="buttonsEngineRight" style={{ position: 'absolute' }}>
                  <div>
                    <Tooltip title={'Telemedicina'}>
                      <IconButton
                        color="secondary"
                        className="navButton"
                        id="navMicButton"
                        onClick={this.toogleOpenMenu}>
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Popover
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 120,
                      horizontal: 'right'
                    }}
                    anchorEl={this.state.containerEl}
                    onClose={this.toogleOpenMenu}
                    style={{ marginBottom: 100 }}
                    open={this.state.openMenuMobile}>
                    <div>
                      <MenuItem
                        style={{ margin: 10 }}
                        onClick={async () => {
                          await this.setStateAsync({
                            openMenuMobile: !this.state.openMenuMobile,
                            menuEsamiOpened: !this.state.menuEsamiOpened
                          });
                        }}>
                        <ListItemIcon>
                          <Badge
                            badgeContent={docCondivisi !== undefined ? docCondivisi.length : 0}
                            color="primary"
                            id="badge">
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                                alignItems: 'center'
                              }}>
                              <DescriptionIcon
                                color="secondary"
                                style={{ width: 50 }}></DescriptionIcon>
                            </div>
                          </Badge>
                        </ListItemIcon>
                        <ListItemText primary="Documenti" />
                      </MenuItem>
                      <MenuItem
                        style={{ margin: 10 }}
                        onClick={async () =>
                          await this.setStateAsync({
                            openMenuMobile: !this.state.openMenuMobile,
                            menuTelemedicinaOpened: !this.state.menuTelemedicinaOpened
                          })
                        }>
                        <ListItemIcon>
                          <Badge
                            badgeContent={
                              misurazioniDevice !== undefined ? misurazioniDevice.length : 0
                            }
                            color="primary"
                            id="badge">
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                                alignItems: 'center'
                              }}>
                              <MonitorHeart style={{ width: 50 }} color="secondary"></MonitorHeart>
                            </div>
                          </Badge>
                        </ListItemIcon>
                        <ListItemText primary="Esami e Misurazioni" />
                      </MenuItem>
                    </div>
                  </Popover>
                </div>
              )}
            </div>
          ) : null}
        </Toolbar>
      </AppBar>
    );
  }
}
