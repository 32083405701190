/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
/* eslint-disable no-empty */
import React from 'react';
import './index.css';
import { Toolbar, AppBar, Badge, Drawer } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DescriptionIcon from '@mui/icons-material/Description';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import PlayerBar from './playerbar';
import AudioStetoPlayer from '@src/components/devices/audiostetoplayer';
import {
  accessoVideoStanza,
  getMeasure,
  getRecords,
  getStanza,
  getDocs,
  urlBackEnd
} from '@src/network/restAPI';
import AccessoVideoStanzaResponse from '@src/network/model/output/accessoVideoStanzaResponse';
import RecordStanza from '@src/network/model/record';
import MeasureStanza from '@src/network/model/measure';
import Stanza from '@src/network/model/stanza';
import { RouteComponentProps } from 'react-router';
import DocStanza from '@src/network/model/doc';
import AbstractScreen from '@src/view/abstractscreen';
import MenuTelemedicina from '../../sessione/engine/menu-telemedicina';
import MenuDocs from '../../sessione/engine/menu-docs';

type Props = RouteComponentProps<{ token: string }>;

type State = {
  roomId: string;
  recId: string;
  currentOffset: number;
  duration: number;
  playerstate: 'stop';
  outputMode: string;
  parti: RecordStanza[];
  parteVideo: RecordStanza | undefined;
  parteSelezionata: string;
  docCondivisi: DocStanza[];
  misurazioniDevice: MeasureStanza[];
  datistanza: Stanza | undefined;
  urlVideo: string | undefined;
  updateTime: boolean;
  menuTelemedicinaOpened: boolean;
  menuEsamiOpened: boolean;
  openAudioStetoPlayer: boolean;
  urlAudio: string;
};

class Player extends AbstractScreen<Props, State> {
  token = '';
  videoRef: React.RefObject<HTMLVideoElement> = React.createRef();

  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      roomId: '',
      recId: '',
      currentOffset: 0.0,
      duration: 0.0,
      playerstate: 'stop',
      outputMode: '',
      parti: [],
      parteVideo: undefined,
      parteSelezionata: 'seleziona',
      docCondivisi: [],
      misurazioniDevice: [],
      datistanza: undefined,
      urlVideo: undefined,
      updateTime: false,

      menuEsamiOpened: false,
      menuTelemedicinaOpened: false,
      openAudioStetoPlayer: false,
      urlAudio: ''
    };

    this.onchangeParte = this.onchangeParte.bind(this);
    this.openAudioPlayer = this.openAudioPlayer.bind(this);
    this.goBack = this.goBack.bind(this);
    this.fetchVideo = this.fetchVideo.bind(this);
  }

  async componentDidMount() {
    this.token = this.props.match.params.token;
    if (this.token !== undefined && this.token !== '' && this.token !== 'undefined') {
      await this.mostraProgressDialog('Caricamento video in corso');
      const response = await accessoVideoStanza(this.token);
      if (response.status !== 200) {
        await this.nascondiProgressDialog();
        await this.mostraDialog(JSON.stringify(response.object), 'error', [
          {
            title: 'Esci',
            onItemPress: this.goBack.bind(this)
          }
        ]);
        return;
      }
      const { data, jwt } = response.object as AccessoVideoStanzaResponse;

      //set JWT token to local
      window.sessionStorage.setItem('access_token', jwt.accessToken);
      window.sessionStorage.setItem('refresh_token', jwt.refreshToken);

      const { roomId } = data;

      await this.setStateAsync({
        roomId
      });

      const response2 = await getStanza();
      if (response2.status === 200) {
        await this.setStateAsync({
          datistanza: response2.object.datiStanza
        });
      }

      const response3 = await getRecords(roomId);
      if (response3.status === 200) {
        await this.nascondiProgressDialog();
        await this.setStateAsync({
          parti: response3.object as RecordStanza[]
        });
      }

      const response4 = await getMeasure();
      if (response4.status === 200) {
        await this.setStateAsync({
          misurazioniDevice: response4.object as MeasureStanza[]
        });
      }

      const response5 = await getDocs();
      if (response5.status === 200) {
        await this.setStateAsync({
          docCondivisi: response5.object as DocStanza[]
        });
      }
      await this.nascondiProgressDialog();
    } else {
      window.location.href = '/#/video';
    }
  }

  async goBack() {
    console.log('goBack');
    if (
      navigator.userAgent.indexOf('APP_SMARTLEA') > -1 ||
      navigator.userAgent.indexOf('APP_CUREDOM') > -1 ||
      navigator.userAgent.indexOf('APP_SL_RN') > -1
    ) {
      if (navigator.userAgent.indexOf('AMBULATORIO') > -1) {
        window.history.back();
      } else {
        if ((window as any).JSInterface) {
          (window as any).JSInterface.exitAccesso('');
        }
      }
    } else {
      window.history.back();
    }
  }

  async openAudioPlayer(urlAudio: string) {
    await this.setStateAsync({
      menuTelemedicinaOpened: false,
      openAudioStetoPlayer: true,
      urlAudio: urlAudio
    });
  }

  /**
   * Evento quando si cambia parte
   */
  async onchangeParte(event: React.ChangeEvent<HTMLSelectElement>) {
    const parte = this.state.parti.filter((element) => element.name === event.target.value);
    if (parte[0] !== undefined) {
      await this.setStateAsync({
        parteSelezionata: event.target.value,
        parteVideo: parte[0],
        outputMode: parte[0].outputMode,
        urlVideo: '/session/file/' + parte[0].dati
      });
      await this.fetchVideo('/session/file/' + parte[0].dati);
    } else {
      await this.setStateAsync({
        parteSelezionata: event.target.value
      });
    }
  }

  async fetchVideo(videoUrl: string) {
    console.log('this.videoRef', this.videoRef);
    if (this.videoRef && this.videoRef.current && window.sessionStorage.getItem('access_token')) {
      const accessToken = window.sessionStorage.getItem('access_token');
      const response = await fetch(urlBackEnd + videoUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (response.ok) {
        const blob = await response.blob();
        const videoObjectUrl = URL.createObjectURL(blob);
        this.videoRef.current.src = videoObjectUrl;
        this.videoRef.current.autoplay = true;
      } else {
        console.error('Failed to fetch video:', response.statusText);
      }
    }
  }

  render() {
    return (
      <div className="container" id="container">
        {this.state.roomId !== '' ? (
          <>
            <AppBar className="toolbar" style={{ top: 'auto', bottom: 0 }} elevation={0}>
              <Toolbar className="toolbar">
                <PlayerBar
                  parti={this.state.parti}
                  parteSelezionata={this.state.parteSelezionata}
                  onchangeParte={this.onchangeParte}></PlayerBar>

                <Drawer
                  style={{ zIndex: 1500 }}
                  anchor={window.matchMedia('(max-width: 700px)').matches ? 'top' : 'right'}
                  open={this.state.menuTelemedicinaOpened}
                  onClose={async () =>
                    await this.setStateAsync({
                      menuTelemedicinaOpened: false
                    })
                  }>
                  <MenuTelemedicina
                    misurazioniDevice={this.state.misurazioniDevice}
                    showDevices={false}
                    openAudio={(urlAudio) => {
                      this.openAudioPlayer(urlAudio);
                    }}
                  />
                </Drawer>

                <Drawer
                  anchor="top"
                  open={this.state.menuEsamiOpened}
                  onClose={async () =>
                    await this.setStateAsync({
                      menuEsamiOpened: false
                    })
                  }>
                  <MenuDocs
                    datistanza={this.state.datistanza}
                    docCondivisi={this.state.docCondivisi}
                    showUpload={false}
                    roomId={''}
                    idPartecipante={''}
                    onDismiss={async () =>
                      await this.setStateAsync({
                        menuEsamiOpened: false
                      })
                    }
                  />
                </Drawer>

                <div className="buttonsRight" style={{ position: 'absolute' }}>
                  <div>
                    <Tooltip title={'Documenti'}>
                      <IconButton
                        color="inherit"
                        className="navButton"
                        id="rightButton"
                        onClick={async () =>
                          await this.setStateAsync({
                            menuEsamiOpened: !this.state.menuEsamiOpened
                          })
                        }>
                        <Badge
                          badgeContent={
                            this.state.docCondivisi !== undefined
                              ? this.state.docCondivisi.length
                              : 0
                          }
                          color="primary"
                          id="badge">
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              flex: 1,
                              alignItems: 'center'
                            }}>
                            <DescriptionIcon
                              color="secondary"
                              style={{ width: '100%' }}></DescriptionIcon>
                          </div>
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </div>

                  <div style={{ width: 60 }}>
                    <Tooltip title={'Device e Misurazioni'}>
                      <IconButton
                        className="navButton"
                        id="rightButton"
                        onClick={async () =>
                          await this.setStateAsync({
                            menuTelemedicinaOpened: !this.state.menuTelemedicinaOpened
                          })
                        }>
                        <Badge
                          badgeContent={
                            this.state.misurazioniDevice !== undefined
                              ? this.state.misurazioniDevice.length
                              : 0
                          }
                          color="primary"
                          id="badge">
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              flex: 1,
                              alignItems: 'center'
                            }}>
                            <MonitorHeartIcon
                              style={{ width: '100%' }}
                              color="secondary"></MonitorHeartIcon>
                          </div>
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </Toolbar>
            </AppBar>
            <div
              id="layout"
              className="bounds"
              style={this.state.urlVideo !== undefined ? { display: 'flex' } : { display: 'none' }}>
              <video
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain'
                }}
                ref={this.videoRef}
                preload="auto"
                controls={true}
              />
            </div>
            <AudioStetoPlayer
              open={this.state.openAudioStetoPlayer}
              urlPath={this.state.urlAudio.replace('/public/', '/stanza/file/')}
              onClose={async () => {
                await this.setStateAsync({
                  urlAudio: '',
                  openAudioStetoPlayer: false
                });
              }}
            />
          </>
        ) : null}

        {super.render()}
      </div>
    );
  }
}

export default Player;
