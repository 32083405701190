/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import './index.css';
import { Container } from '@mui/material';
import AbstractScreen from '@src/view/abstractscreen';
import WelcomeScreen from '../welcome';

type Props = {};

type State = {};

class Login extends AbstractScreen<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state
    };
  }

  render() {
    return (
      <Container maxWidth="sm">
        <WelcomeScreen />
        {super.render()}
      </Container>
    );
  }
}

export default Login;
