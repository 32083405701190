/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
import {
  Button,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  IconButton
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import './config.room.scss';
import data from '@src/components/constant';
import MicrophoneVisualizer from '@src/components/microphoneVisualizer';
import MicOff from '@mui/icons-material/MicOff';
import Mic from '@mui/icons-material/Mic';
import Videocam from '@mui/icons-material/Videocam';
import VideocamOff from '@mui/icons-material/VideocamOff';

type Props = {
  visible: boolean;
  onAccesso: (activeMic: boolean, micID: string, activeWebcam: boolean, webcamID: string) => void;
};

const ConfigRoom = (props: Props) => {
  const videoStreamRef = useRef<MediaStream | null>(null);

  const { visible, onAccesso } = props;

  const [elencoMic, setElencoMic] = useState<MediaDeviceInfo[]>([]);
  const [elencoWebcam, setElencoWebcam] = useState<MediaDeviceInfo[]>([]);

  const [selectedIdWebcam, setSelectedIdWebcam] = useState<string>('');
  const [selectedIdMic, setSelectedIdMic] = useState<string>('');

  const [activeMic, setActiveMic] = useState<boolean>(true);
  const [activeWebcam, setActiveWebcam] = useState<boolean>(true);

  useEffect(() => {
    askForCameraAndMicrophonePermissions().then((value) => {
      if (value === true && navigator.mediaDevices) {
        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            console.log(devices);
            const audioDevices = devices.filter(
              (device) => device.kind === 'audioinput' && device.deviceId !== 'default'
            );

            const videoDevices = devices.filter(
              (device) => device.kind === 'videoinput' && device.deviceId !== 'default'
            );
            const check_granted_mic = audioDevices[0].label;
            const check_granted_cam = videoDevices[0].label;
            if (check_granted_mic !== '' && check_granted_cam !== '') {
              setElencoMic(audioDevices);

              setElencoWebcam(videoDevices);
              if (navigator.userAgent.indexOf('KHADAS') > -1) {
                setSelectedIdMic(audioDevices[audioDevices.length - 1].deviceId);
                setSelectedIdWebcam(videoDevices[videoDevices.length - 1].deviceId);
                return navigator.mediaDevices.getUserMedia({
                  facingMode: { exact: 'user' },
                  audio: {
                    deviceId: audioDevices[audioDevices.length - 1].deviceId
                  },
                  video: {
                    deviceId: videoDevices[videoDevices.length - 1].deviceId
                  }
                } as MediaStreamConstraints);
              } else {
                setSelectedIdMic(audioDevices[0].deviceId);
                setSelectedIdWebcam(videoDevices[0].deviceId);
                return navigator.mediaDevices.getUserMedia({
                  facingMode: { exact: 'user' },
                  audio: {
                    deviceId: audioDevices[0].deviceId
                  },
                  video: {
                    deviceId: videoDevices[0].deviceId
                  }
                } as MediaStreamConstraints);
              }
            } else {
              throw new Error('Permessi non forniti');
            }
          })
          .then(handleSuccess)
          .catch(handleError);
      }
    });

    return () => {
      if (videoStreamRef.current) {
        videoStreamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, [activeWebcam]);

  const handleError = (error: Error) => {
    if (error.message === 'Permission denied') {
      alert('Fornire Permessi Webcam e Microfono e Aggiornare la pagina');
    }
    if (error.message === 'Permessi non forniti') {
      setActiveMic(false);
      setActiveWebcam(false);
    }
  };

  const handleSuccess = (stream: MediaStream) => {
    const video = document.getElementById('test-video') as HTMLVideoElement;
    videoStreamRef.current = stream;
    video.srcObject = stream;
    video.style.objectFit = 'contain';
    video.style.backgroundColor = 'black';
  };

  async function askForCameraAndMicrophonePermissions(): Promise<boolean> {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      const videoTrack = stream.getVideoTracks()[0];
      const audioTrack = stream.getAudioTracks()[0];
      return videoTrack.label !== '' && audioTrack.label !== '';
    } catch (error) {
      console.error('Impossibile accedere alla webcam o al microfono:', error);
      setActiveMic(false);
      setActiveWebcam(false);
      return false;
    }
  }

  const handleVideoChange = async (ev: SelectChangeEvent<string>) => {
    setSelectedIdWebcam(ev.target.value as string);

    navigator.mediaDevices
      .getUserMedia({
        facingMode: { exact: 'user' },
        audio: {
          deviceId: selectedIdMic
        },
        video: {
          deviceId: ev.target.value as string
        }
      } as MediaStreamConstraints)
      .then(handleSuccess)
      .catch(handleError);
  };

  const handleMicChange = (ev: SelectChangeEvent<string>) => {
    setSelectedIdMic(ev.target.value as string);
  };

  return visible ? (
    <Dialog open={visible}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#fff'
        }}>
        <div className="container-video" style={{ textAlign: 'center', padding: 10 }}>
          <div className="test-video">
            <div className="container-player">
              {activeWebcam ? (
                <video
                  id="test-video"
                  className="testvideo"
                  playsInline={true}
                  autoPlay={true}
                  muted={true}
                />
              ) : (
                <div style={{ backgroundColor: 'black' }} className="testvideo"></div>
              )}
              {selectedIdMic !== '' && activeMic ? (
                <MicrophoneVisualizer
                  heightVisualizer={50}
                  deviceId={selectedIdMic}
                  style={{
                    padding: 10,
                    height: 50,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0
                  }}
                />
              ) : null}
            </div>
          </div>
          <div className="test-audio">
            {elencoWebcam.length > 0 ? (
              <FormControl variant="standard" sx={{ m: 1, minWidth: '95%', maxWidth: 200 }}>
                <InputLabel id="webcam-standard">Webcam</InputLabel>
                <Select
                  labelId="webcam-standard"
                  id="webcam-standard"
                  value={selectedIdWebcam}
                  onChange={handleVideoChange}>
                  {elencoWebcam.map((item, i) => (
                    <MenuItem key={i} value={item.deviceId}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
            {elencoMic.length > 0 ? (
              <FormControl variant="standard" sx={{ m: 1, minWidth: '95%', maxWidth: 200 }}>
                <InputLabel id="microphone-standard">Microfono</InputLabel>
                <Select
                  labelId="microphone-standard"
                  id="microphone-standard"
                  value={selectedIdMic}
                  onChange={handleMicChange}>
                  {elencoMic.map((item, i) => (
                    <MenuItem key={i} value={item.deviceId}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <IconButton
                color="secondary"
                className="navButton"
                id="navMicButton"
                onClick={() => setActiveMic(!activeMic)}>
                {activeMic ? <Mic color="secondary" /> : <MicOff color="primary" />}
              </IconButton>
              <IconButton
                color="secondary"
                className="navButton"
                id="navCameraButton"
                onClick={() => setActiveWebcam(!activeWebcam)}>
                {activeWebcam ? <Videocam color="secondary" /> : <VideocamOff color="primary" />}
              </IconButton>
            </div>
          </div>
        </div>
        <Button
          style={{ margin: 10, backgroundColor: data.primary }}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => onAccesso(activeMic, selectedIdMic, activeWebcam, selectedIdWebcam)}>
          Accesso
        </Button>
      </div>
    </Dialog>
  ) : null;
};

export default ConfigRoom;
