/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import MicrophoneVisualizer from '@src/components/microphoneVisualizer';

const ConfigRoom = () => {
  const videoStreamRef = useRef<MediaStream | null>(null);

  useEffect(() => {
    const handleError = (error: Error) => {
      if (error.message === 'Permission denied') {
        alert('Fornire Permessi Webcam e Microfono e Aggiornare la pagina');
      }
    };

    const handleSuccess = (stream: MediaStream) => {
      const video = document.getElementById('test-video') as HTMLVideoElement;
      videoStreamRef.current = stream;
      video.srcObject = stream;
      video.style.objectFit = 'contain';
      video.style.backgroundColor = 'black';
    };
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const videoDevices = devices.filter(
          (device) => device.kind === 'videoinput' && device.deviceId !== 'default'
        );
        if (navigator.userAgent.indexOf('KHADAS') > -1) {
          return navigator.mediaDevices.getUserMedia({
            facingMode: { exact: 'user' },
            video: {
              deviceId: videoDevices[videoDevices.length - 1].deviceId
            }
          } as MediaStreamConstraints);
        } else {
          return navigator.mediaDevices.getUserMedia({
            facingMode: { exact: 'user' },
            video: {
              deviceId: videoDevices[0].deviceId
            }
          } as MediaStreamConstraints);
        }
      })
      .then(handleSuccess)
      .catch(handleError);

    // Funzione di cleanup per fermare la cattura audio e rilasciare le risorse
    return () => {
      if (videoStreamRef.current) {
        videoStreamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  return (
    <div
      style={{
        backgroundColor: '#ccc',
        height: '100vh',
        display: 'flex',
        flex: 1,
        alignItems: 'center'
      }}>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div
          style={{
            background: '#fff',
            width: '70vw',
            height: '90vh',
            margin: '0 auto',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            padding: 20
          }}>
          <video
            id="test-video"
            style={{
              height: '50vh'
            }}
            playsInline={true}
            autoPlay={true}
            muted={true}></video>
          <div className="test-audio">
            <div style={{ textAlign: 'center' }}>
              <MicrophoneVisualizer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigRoom;
