/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import './index.css';
import {
  Connection,
  OpenVidu,
  Publisher,
  Session,
  SignalEvent,
  SignalOptions,
  Stream,
  StreamEvent
} from 'openvidu-browser';
import UserModel from '@src/models/user-model';
import ToolbarComponent from './toolbar';
import MaterialDialog from '@src/components/base/material-dialog';
import StreamComponent from './stream';
import MaterialProgressDialog from '@src/components/base/material-progress';
import Notification from './notification';
import logger from '@src/utils/logger';
import ConfigRoom from './config-room';
import getMobileOperatingSystem from '@src/utils/checkOS';
import EasyTimer from 'easytimer.js';
import InputOssimetro from '@src/components/devices/ossimetro';
import InputSfigmomanometro from '@src/components/devices/sfigmomanometro';
import InputBilancia from '@src/components/devices/bilancia';
import InputGlucometro from '@src/components/devices/glucometro';
import InputTermometro from '@src/components/devices/termometro';
import AudioStetoPlayer from '@src/components/devices/audiostetoplayer';
import {
  accessoStanza,
  checkAccesso,
  closeStanza,
  esciStanza,
  getDocs,
  getMeasure,
  getStanza,
  insertMeasure,
  insertWebMeasure,
  ping,
  startIPCamera,
  startRecord,
  stetoscopeOFF,
  stetoscopeON,
  stopIPCamera,
  stopRecord
} from '@src/network/restAPI';
import Partecipante from '@src/network/model/partecipante';
import Stanza from '@src/network/model/stanza';
import DocStanza from '@src/network/model/doc';
import MeasureStanza from '@src/network/model/measure';
import { RouteComponentProps } from 'react-router';
import AccessoStanzaResponse from '@src/network/model/output/accessoStanzaResponse';
import SendMeasureInput from '@src/network/model/input/sendMeasureInput';
import SendWebMeasureInput from '@src/network/model/input/sendWebMeasureInput';
import AbstractScreen from '@src/view/abstractscreen';

const typeVideo = 'contain';

const localUser: Partial<UserModel> = {
  connectionId: '',
  nickname: '',
  type: 'local',
  audioActive: true,
  videoActive: true,
  screenShareActive: false,
  data: undefined,
  isBackCameraActive: false,
  muted: false
};

const TIMERNOTIFICA = 3;

type Props = RouteComponentProps<{ token: string }>;

type State = {
  timer: any;
  timeValues: string;

  openInputOssimetro: boolean;
  openInputSfigmo: boolean;
  openInputBilancia: boolean;
  openInputGlucometro: boolean;
  openInputTermometro: boolean;
  openAudioStetoPlayer: boolean;
  urlAudio: string;

  selectedIdMic: string;
  selectedIdWeb: string;

  showNotifica: boolean;
  messageNotifica: string;
  typeNotifica: 'doc' | 'measure' | 'message' | 'file' | 'static'; //doc, measure, message
  contentNotifica: string;
  progressNotifica: number; //max secondi visibile
  countdownNotifica: NodeJS.Timeout | undefined;
  imageNotifica: string;

  showConfigRoom: boolean;
  showToolbar: boolean;

  mySessionId: string;
  myUserName: string;
  myLocalUserName: string;
  partecipante: Partecipante | undefined;
  session: Session | undefined;
  localUser: Partial<UserModel> | undefined;
  publisher: Publisher | undefined;
  subscribers: Partial<UserModel>[];
  tokenOV: string;
  datiStanza: Stanza | undefined;
  datiUtente: Partecipante | undefined;
  recAttiva: string;
  docCondivisi: DocStanza[];
  misurazioniDevice: MeasureStanza[];
  isFrontCamera: boolean;
  numero_device: number;
  connectionId: string;
  ipWorkStation: string;
  idConnessioneSteto: string;
  idConnessione: string;
  cameraAttiva: boolean; //blocca l'utilizzo di altre camere se almeno una è attiva
  latencyUsers: Map<string, number>;
};

class Engine extends AbstractScreen<Props, State> {
  hasBeenUpdated: boolean;
  token: string;
  OV: OpenVidu | undefined;
  timerPing: NodeJS.Timeout;
  timerAutoPlay: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);

    this.hasBeenUpdated = false;

    //Nome della Stanza
    const sessionName = '';
    //Username Locale
    const userName = '';
    const localUsername = '';

    this.state = {
      ...this.state,
      timer: new EasyTimer(),
      timeValues: '',

      selectedIdMic: '',
      selectedIdWeb: '',

      openInputOssimetro: false,
      openInputSfigmo: false,
      openInputBilancia: false,
      openInputGlucometro: false,
      openInputTermometro: false,
      openAudioStetoPlayer: false,
      urlAudio: '',

      showNotifica: false,
      messageNotifica: '',
      typeNotifica: 'message', //doc, measure, message
      contentNotifica: '',
      progressNotifica: 3, //max secondi visibile
      countdownNotifica: undefined,
      imageNotifica: 'assets/images/notify.svg',

      showConfigRoom: true,
      showToolbar: false,

      mySessionId: sessionName,
      myUserName: userName,
      myLocalUserName: localUsername,
      partecipante: undefined,
      session: undefined,
      localUser: undefined,
      publisher: undefined,
      subscribers: [],
      tokenOV: '',
      datiStanza: undefined,
      datiUtente: undefined,
      recAttiva: 'false',
      docCondivisi: [],
      misurazioniDevice: [],
      isFrontCamera: false,
      numero_device: 0,
      connectionId: '',

      ipWorkStation: '',

      idConnessioneSteto: '',
      idConnessione: '',
      cameraAttiva: false, //blocca l'utilizzo di altre camere se almeno una è attiva

      latencyUsers: new Map()
    };

    this.accessoSessione = this.accessoSessione.bind(this);
    this.checkMobile = this.checkMobile.bind(this);
    this.joinSession = this.joinSession.bind(this);
    this.leaveSession = this.leaveSession.bind(this);
    this.onbeforeunload = this.onbeforeunload.bind(this);
    this.updateLayout = this.updateLayout.bind(this);
    this.camStatusChanged = this.camStatusChanged.bind(this);
    this.micStatusChanged = this.micStatusChanged.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.screenShare = this.screenShare.bind(this);
    this.stopScreenShare = this.stopScreenShare.bind(this);
    this.startRecording = this.startRecording.bind(this);
    this.stopRecording = this.stopRecording.bind(this);
    this.exitSession = this.exitSession.bind(this);
    this.closeSession = this.closeSession.bind(this);
    this.onPressDevice = this.onPressDevice.bind(this);
    this.mostraNotifica = this.mostraNotifica.bind(this);
    this.nascondiNotifica = this.nascondiNotifica.bind(this);
    this.timerNotifica = this.timerNotifica.bind(this);

    this.sendMeasurement = this.sendMeasurement.bind(this);
    this.getNumberOfCams = this.getNumberOfCams.bind(this);

    this.switchCamera = this.switchCamera.bind(this);
    this.refreshCamera = this.refreshCamera.bind(this);
    this.enumerateDevices = this.enumerateDevices.bind(this);

    this.autoPlayAll = this.autoPlayAll.bind(this);
    this.tick = this.tick.bind(this);
    this.setIPWorkStation = this.setIPWorkStation.bind(this);
    this.executeAvviaIPCamera = this.executeAvviaIPCamera.bind(this);
    this.closeIPCamera = this.closeIPCamera.bind(this);
    this.getIPWorkstation = this.getIPWorkstation.bind(this);
    this.avviaStetoscopio = this.avviaStetoscopio.bind(this);
    this.closeStetoscopio = this.closeStetoscopio.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goBackAndRedirect = this.goBackAndRedirect.bind(this);
    this.executePing = this.executePing.bind(this);
    this.convertMillisecToValue = this.convertMillisecToValue.bind(this);

    //Devices
    this.closeInputDeviceOssimetro = this.closeInputDeviceOssimetro.bind(this);
    this.sendInputDeviceOssimetro = this.sendInputDeviceOssimetro.bind(this);

    this.closeInputDeviceSfigmo = this.closeInputDeviceSfigmo.bind(this);
    this.sendInputDeviceSfigmo = this.sendInputDeviceSfigmo.bind(this);

    this.closeInputDeviceBilancia = this.closeInputDeviceBilancia.bind(this);
    this.sendInputDeviceBilancia = this.sendInputDeviceBilancia.bind(this);

    this.closeInputDeviceGlucometro = this.closeInputDeviceGlucometro.bind(this);
    this.sendInputDeviceGlucometro = this.sendInputDeviceGlucometro.bind(this);

    this.closeInputDeviceTermometro = this.closeInputDeviceTermometro.bind(this);
    this.sendInputDeviceTermometro = this.sendInputDeviceTermometro.bind(this);
    this.openAudioPlayer = this.openAudioPlayer.bind(this);

    this.goToRedirect = this.goToRedirect.bind(this);
  }

  /**
   * Lettura token appena entro nella pagina
   */
  async componentDidMount() {
    console.log('token', this.props.match);

    //Catturo il token dal url query
    this.token = this.props.match.params.token;
    if (this.token !== undefined && this.token !== '' && this.token !== 'undefined') {
      window.addEventListener('beforeunload', this.onbeforeunload);
      window.addEventListener('resize', this.updateLayout);

      if (getMobileOperatingSystem() !== 'PC') {
        if (
          navigator.userAgent.includes('APP_SMARTLEA') ||
          navigator.userAgent.includes('APP_CUREDOM') ||
          navigator.userAgent.includes('APP_SL_RN')
        ) {
          //Chiamare Server API per decodifica token accesso
          this.accessoSessione(true, '', true, '');
        }
      } else {
        await this.setStateAsync({
          showConfigRoom: true
        });
      }
    } else {
      window.location.href = '/';
    }
    (window as any).sendMeasurement = this.sendMeasurement;
    (window as any).leaveSession = this.leaveSession;
    (window as any).setIPWorkStation = this.setIPWorkStation;
    (window as any).refreshCamera = this.refreshCamera;
    (window as any).autoPlayAll = this.autoPlayAll;
    (window as any).stetoON = this.avviaStetoscopio;
    (window as any).stetoOFF = this.closeStetoscopio;
  }

  /**
   * Comando per avviare i video tag
   */
  autoPlayAll() {
    try {
      this.timerAutoPlay = setTimeout(async () => {
        console.log('PLAY');
        const elenco = document.getElementsByTagName('video');
        if (elenco !== null) {
          for (let i = 0; i < elenco.length; i++) {
            const video = elenco[i];
            const isPlaying =
              video.currentTime > 0 &&
              !video.paused &&
              !video.ended &&
              video.readyState > video.HAVE_CURRENT_DATA;
            if (!isPlaying) {
              video.play();
            }
          }
        }
      }, 5000);
    } catch (error) {
      clearInterval(this.timerAutoPlay);
    }
  }

  async goBack() {
    console.log('goBack');
    if (
      navigator.userAgent.indexOf('APP_SMARTLEA') > -1 ||
      navigator.userAgent.indexOf('APP_CUREDOM') > -1 ||
      navigator.userAgent.indexOf('APP_SL_RN') > -1
    ) {
      if (navigator.userAgent.indexOf('AMBULATORIO') > -1) {
        window.history.back();
      } else {
        if ((window as any).JSInterface) {
          (window as any).JSInterface.exitAccesso('');
        }
      }
    } else {
      window.history.back();
    }
  }

  goBackAndRedirect() {
    console.log('goBack');
    if (
      navigator.userAgent.indexOf('APP_SMARTLEA') > -1 ||
      navigator.userAgent.indexOf('APP_CUREDOM') > -1 ||
      navigator.userAgent.indexOf('APP_SL_RN') > -1
    ) {
      if (navigator.userAgent.indexOf('AMBULATORIO') > -1) {
        this.goToRedirect();
      } else {
        if ((window as any).JSInterface) {
          (window as any).JSInterface.exitAccesso('');
        }
      }
    } else {
      this.goToRedirect();
    }
  }

  /**
   * Funzione per mandare i partecipanti nella pagina web corrispondente
   */
  async goToRedirect() {
    if (this.state.datiStanza && this.state.partecipante) {
      console.log('goToRedirect', 'PRIMA');
      const domain = this.state.datiStanza.domain;
      const result = this.state.datiStanza.roomId.split('|');
      const idStanza = result[1];
      const username = this.state.partecipante.username;
      const redirect = this.state.partecipante.urlredirect;
      console.log('goToRedirect', domain, result, idStanza, username, redirect);

      //Pulisco lo stato
      await this.setStateAsync({
        session: undefined,
        subscribers: [],
        mySessionId: '',
        myUserName: '',
        partecipante: undefined,
        localUser: undefined,
        datiStanza: undefined
      });

      if (idStanza && username && redirect) {
        let url;
        if (username.indexOf('ass_') > -1) {
          const idAssistito = username.split('_')[1];
          if (redirect.includes('?')) {
            url = domain + redirect + '&idstanza=' + idStanza + '&idAssistito=' + idAssistito;
          } else {
            url = domain + redirect + '?idstanza=' + idStanza + '&idAssistito=' + idAssistito;
          }
        } else {
          if (redirect.includes('?')) {
            url = domain + redirect + '&idstanza=' + idStanza + '&idoperatore=' + username;
          } else {
            url = domain + redirect + '?idstanza=' + idStanza + '&idoperatore=' + username;
          }
        }
        console.log(url);
        window.location.href = url;
      } else {
        window.location.href = '/';
      }
    }
  }

  /**
   * Comando per settare IP in Sessione
   */
  async setIPWorkStation(ip: string) {
    await this.setStateAsync({
      ipWorkStation: ip
    });
    console.log('IP Settato');
  }

  /**
   * Funzione di aggiornamento del tempo
   */
  async tick() {
    const { timer } = this.state;
    const timeValues = timer.getTimeValues().toString();
    await this.setStateAsync({ timeValues: timeValues });
  }

  /**
   * Cattura numero webcam presenti sul device
   */
  async getNumberOfCams() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const elencoWebcam = [];
      for (let i = 0; i < devices.length; i++) {
        const device = devices[i];
        if (device.kind === 'videoinput') {
          elencoWebcam.push(device);
        }
      }
      await this.setStateAsync({
        numero_device: elencoWebcam.length
      });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Accesso Alla sessione
   */
  async accessoSessione(
    activeMic: boolean,
    selectedIdMic: string,
    activeWebcam: boolean,
    selectedIdWeb: string
  ) {
    localUser.audioActive = activeMic;
    localUser.videoActive = activeWebcam;
    await this.setStateAsync({
      showConfigRoom: false,
      selectedIdMic,
      selectedIdWeb
    });
    //Chiamare Server API per decodifica token accesso
    this.executeAccessoStanza();
  }

  /**
   * Lascio la sessione se chiudo la scheda browser
   */
  async componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onbeforeunload);
    window.removeEventListener('resize', this.updateLayout);
    window.removeEventListener('secondsUpdated', this.tick);
    try {
      if (this.OV) {
        this.OV.closeWs();
        await this.setStateAsync({
          session: undefined
        });
        this.OV = undefined;
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Lascio la stanza quando chiudo la scheda
   */
  onbeforeunload() {
    this.leaveSession();
  }

  /**
   * Chiamata REST per ottenere token openvidu per accedere alla stanza
   */
  async executeAccessoStanza() {
    await this.mostraProgressDialog('Controllo Partecipante in corso...');
    const response = await accessoStanza(this.token);
    if (response.object === null) {
      return;
    }

    if (response.status !== 200 && typeof response.object === 'string') {
      await this.nascondiProgressDialog();
      await this.mostraDialog(response.object, 'error', [
        {
          title: 'Esci',
          onItemPress: this.goBack.bind(this)
        }
      ]);
      return;
    }

    const { partecipante, token, idSessione, jwt } = response.object as AccessoStanzaResponse;

    //set JWT token to local
    window.sessionStorage.setItem('access_token', jwt.accessToken);
    window.sessionStorage.setItem('refresh_token', jwt.refreshToken);

    const response2 = await checkAccesso();
    if (response2.object === null) {
      return;
    }

    if (response2.status !== 200) {
      await this.nascondiProgressDialog();
      await this.mostraDialog(response2.object, 'error', [
        {
          title: 'Esci',
          onItemPress: this.goBack.bind(this)
        }
      ]);
      return;
    }

    await this.nascondiProgressDialog();
    await this.mostraProgressDialog('Accesso in corso...');

    await this.setStateAsync({
      tokenOV: token,
      myUserName: `${partecipante.cognome.replace('&#39;', "'")} ${partecipante.nome.replace(
        '&#39;',
        "'"
      )}`,
      myLocalUserName: partecipante.username,
      mySessionId: idSessione,
      partecipante
    });

    const response3 = await getStanza();
    if (response3.status !== 200) {
      return;
    }

    if (response3.object === null) {
      return;
    }

    const { datiStanza } = response3.object;
    await this.nascondiProgressDialog();
    await this.setStateAsync({
      datiStanza,
      recAttiva: datiStanza.recAttiva !== 'undefined' ? datiStanza.recAttiva : 'false'
    });
    console.log(datiStanza.sessioneAttiva);
    if (datiStanza.sessioneAttiva !== 'closed') {
      console.log('Entro nella stanza');
      this.joinSession();
    } else {
      await this.nascondiProgressDialog();
      this.leaveSession();
    }

    const response4 = await getDocs();
    if (response4.object === null) {
      return;
    }

    if (response4.status === 200) {
      await this.setStateAsync({
        docCondivisi: response4.object
      });
    }

    const response5 = await getMeasure();
    if (response5.object === null) {
      return;
    }

    if (response5.status === 200) {
      await this.setStateAsync({
        misurazioniDevice: response5.object
      });
    }
  }

  /**
   * Ritorno al Login
   */
  exit() {
    window.location.href = '/';
  }

  /**
   * Inizializzo la sessionee Video
   */
  async joinSession() {
    this.OV = new OpenVidu();
    this.OV.enableProdMode();
    this.OV.setAdvancedConfiguration({
      iceServers: [
        { urls: 'stun:stun.l.google.com:19302' },
        {
          urls: `turn:${window.location.hostname}:3478`,
          username: 'innova',
          credential: 'innova'
        }
      ]
    });
    await this.setStateAsync({
      session: this.OV.initSession()
    });
    this.subscribeToStreamCreated();
    this.connectToSession();
    this.getNumberOfCams();
    this.getIPWorkstation();
  }

  /**
   * Comunicazione con app Android per ottenere ip workstation
   */
  getIPWorkstation() {
    if (
      navigator.userAgent.indexOf('APP_SMARTLEA') > -1 ||
      navigator.userAgent.indexOf('APP_CUREDOM') > -1 ||
      navigator.userAgent.indexOf('APP_SL_RN') > -1
    ) {
      if (navigator.userAgent.indexOf('AMBULATORIO') > -1) {
        if (navigator.userAgent.indexOf('GECKOVIEW') > -1) {
          const message = {
            type: 'getIpWorkstation'
          };
          window.postMessage(message, '*');
        } else {
          console.log('Chiamo JS per ottenere IP Workstation');
          if ((window as any).JSInterface) {
            (window as any).JSInterface.getIpWorkstation();
          }
        }
      }
    }
  }

  /**
   * Ricevuto il token mi collego alla stanza
   */
  connectToSession() {
    if (this.state.tokenOV !== undefined) {
      this.connect(this.state.tokenOV);
    }
  }

  /**
   * Connessione alla Sessione
   */
  async connect(wsToken: string) {
    if (this.state.session) {
      try {
        await this.state.session.connect(wsToken, {
          clientData: this.state.myUserName
        });
        //Collego la Webcam
        this.subscribeToSessionMessages();
        this.connectWebCam();
        await this.setStateAsync({
          showToolbar: true
        });
      } catch (error) {
        alert('There was an error connecting to the session: ' + error);
        logger('There was an error connecting to the session: ', error);
      }
    }
  }

  /**
   * Controllo device audio e video
   */
  async enumerateDevices() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioDevices = devices.filter(
      (device) => device.kind === 'audioinput' && device.deviceId !== 'default'
    );
    const videoDevices = devices.filter(
      (device) => device.kind === 'videoinput' && device.deviceId !== 'default'
    );
    if (navigator.userAgent.indexOf('KHADAS') > -1) {
      const frontVideo = videoDevices[videoDevices.length - 1];
      const frontAudio = audioDevices[audioDevices.length - 1];
      return { frontVideo, frontAudio };
    } else {
      const frontVideo = videoDevices[0];
      const frontAudio = audioDevices[0];
      return { frontVideo, frontAudio };
    }
  }

  /**
   * Pubblico la Webcam
   */
  async connectWebCam() {
    console.log('Connect Webcam');

    if (this.OV) {
      try {
        if (this.state.selectedIdMic === '' && this.state.selectedIdWeb === '') {
          const { frontVideo, frontAudio } = await this.enumerateDevices();
          console.log({ frontVideo, frontAudio });
          localUser.videoSource = frontVideo;
          localUser.audioSource = frontAudio;
        } else {
          const devices = await navigator.mediaDevices.enumerateDevices();
          const audioDevices = devices.filter(
            (device) => device.kind === 'audioinput' && device.deviceId === this.state.selectedIdMic
          );
          const videoDevices = devices.filter(
            (device) => device.kind === 'videoinput' && device.deviceId === this.state.selectedIdWeb
          );
          localUser.videoSource = videoDevices[0];
          localUser.audioSource = audioDevices[0];
        }

        console.log(localUser.audioSource, localUser.videoSource);

        const publisher = this.OV.initPublisher('', {
          videoSource: localUser.videoSource.deviceId,
          audioSource: localUser.audioSource.deviceId,
          publishAudio: localUser.audioActive,
          publishVideo: localUser.videoActive,
          resolution: '640x480',
          mirror: this.state.isFrontCamera,
          frameRate: 30,
          insertMode: 'APPEND'
        });

        if (this.state.session && this.state.session.capabilities.publish) {
          await this.state.session.publish(publisher);
          localUser.data = this.state.partecipante;
          localUser.nickname = this.state.myUserName.replace('&#39;', "'");
          localUser.connectionId = this.state.session.connection.connectionId;
          localUser.screenShareActive = false;
          localUser.streamManager = publisher;

          console.log(localUser);

          this.subscribeToUserChanged();
          this.subscribeToStreamDestroyed();
          await this.sendSignalUserChanged({
            isScreenShareActive: localUser.screenShareActive
          });

          await this.setStateAsync({ localUser: localUser });
          if (this.state.localUser?.streamManager) {
            (this.state.localUser?.streamManager as Publisher).on('streamPlaying', () => {
              this.updateLayout();
            });
          }

          await this.setStateAsync({
            isFrontCamera: !this.state.isFrontCamera
          });

          this.executePing();

          this.autoPlayAll();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  /**
   * Controllo Latenza con server streaming
   */
  async executePing() {
    try {
      if (this.state.partecipante) {
        const response = await ping();
        const millisec = response.object;
        const valore = this.convertMillisecToValue(millisec);
        const latencyUsers = this.state.latencyUsers;
        latencyUsers.set(this.state.partecipante.id, valore);
        await this.setStateAsync({
          latencyUsers: latencyUsers
        });
        const signalOptions = {
          data: JSON.stringify({
            id: this.state.partecipante.id,
            time: latencyUsers.get(this.state.partecipante.id)
          }),
          type: 'userLatency'
        };
        await this.sendMessage(signalOptions);
        this.timerPing = setTimeout(this.executePing, 3000);
      }
    } catch (error) {
      if (this.timerPing) {
        clearTimeout(this.timerPing);
      }
    }
  }

  convertMillisecToValue(millisec: number) {
    let value = 0;
    if (millisec <= 100) {
      value = 5;
    } else if (millisec > 100 && millisec <= 200) {
      value = 4;
    } else if (millisec > 200 && millisec <= 300) {
      value = 3;
    } else if (millisec > 300 && millisec <= 400) {
      value = 2;
    } else if (millisec > 400) {
      value = 1;
    }
    return value;
  }

  /**
   * Esco la Sessione e pulisco lo useState
   */
  async leaveSession(withClosed = false) {
    await this.nascondiDialog();
    console.log(this.state.datiStanza);
    console.log(this.state.partecipante);
    if (this.state.datiStanza !== undefined && this.state.partecipante !== undefined) {
      console.log('LocalUser: ' + localUser.streamManager);
      if (localUser.streamManager) {
        await this.mostraProgressDialog('Uscita dalla sessione in corso...');
        const response = await esciStanza(localUser.streamManager.stream.streamId);
        if (response.status !== 200) {
          await this.nascondiProgressDialog();
          await this.mostraDialog(JSON.stringify(response.object), 'error');
          return;
        }

        await this.nascondiProgressDialog();
        if (withClosed) {
          this.goBackAndRedirect();
        } else {
          this.goBack();
        }
      } else {
        console.log('torno indietro');
        window.history.back();
      }
    } else {
      console.log('torno indietro');
      window.history.back();
    }
  }

  /**
   * Controllore Attiva/Disattiva CAM
   */
  async camStatusChanged() {
    localUser.videoActive = !localUser.videoActive;
    if (localUser.streamManager) {
      (localUser.streamManager as Publisher).publishVideo(localUser.videoActive);
    }
    await this.sendSignalUserChanged({ isVideoActive: localUser.videoActive });
    await this.setStateAsync({ localUser: localUser });
  }

  /**
   * Controllore Attiva/Disattiva MIC
   */
  async micStatusChanged() {
    localUser.audioActive = !localUser.audioActive;
    if (localUser.streamManager) {
      (localUser.streamManager as Publisher).publishAudio(localUser.audioActive);
    }
    await this.sendSignalUserChanged({ isAudioActive: localUser.audioActive });
    await this.setStateAsync({ localUser: localUser });
  }

  /**
   * Rimozione stream dagli utenti
   */
  async deleteSubscriber(stream: Stream) {
    const remoteUsers = this.state.subscribers;
    const userStream = remoteUsers.filter((user) => user.streamManager.stream === stream)[0];
    const index = remoteUsers.indexOf(userStream, 0);
    if (index > -1) {
      remoteUsers.splice(index, 1);
      await this.setStateAsync({
        subscribers: remoteUsers
      });
    }
  }

  /**
   * Evento Stream Creato
   */
  async subscribeToStreamCreated() {
    if (this.state.session) {
      this.state.session.on('streamCreated', async (event) => {
        const subscriber = this.state.session.subscribe((event as StreamEvent).stream, '');
        const subscribers = this.state.subscribers;
        subscriber.on('streamPlaying', () => {
          this.checkSomeoneShareScreen();
        });
        const newUser: Partial<UserModel> = {
          streamManager: subscriber,
          connectionId: (event as StreamEvent).stream.connection.connectionId,
          nickname: '',
          type: 'remote',
          audioActive: true,
          videoActive: true,
          screenShareActive: false,
          data: undefined,
          isBackCameraActive: false,
          muted: false
        };

        (event as StreamEvent).stream.connection.data = unescape(
          (event as StreamEvent).stream.connection.data
        );
        await this.setStateAsync({
          connectionId: (event as StreamEvent).stream.connection.connectionId
        });

        if ((event as StreamEvent).stream.typeOfVideo === 'IPCAM') {
          newUser.data = 'IPCAM';
          newUser.nickname = 'IPCAM';
          newUser.screenShareActive = true;
        } else {
          const nickname = (event as StreamEvent).stream.connection.data.split('%')[0];
          const datiPartecipante = (event as StreamEvent).stream.connection.data.split('%')[2];
          console.log('datiPartecipante', (event as StreamEvent).stream.connection.data);
          newUser.data = JSON.parse(datiPartecipante) as Partecipante;
          newUser.nickname = JSON.parse(nickname).clientData.replace('&#39;', "'");
        }
        subscribers.push(newUser);
        await this.setStateAsync({
          subscribers: subscribers
        });
        if (this.state.localUser) {
          await this.sendSignalUserChanged({
            isAudioActive: this.state.localUser.audioActive,
            isVideoActive: this.state.localUser.videoActive,
            nickname: this.state.localUser.nickname,
            isScreenShareActive: this.state.localUser.screenShareActive
          });
        }
        this.updateLayout();
      });
    }
  }

  /**
   * Controllore se uno Stream (Partecipante) è uscito o disconnesso
   */
  subscribeToStreamDestroyed() {
    if (this.state.session) {
      this.state.session.on('streamDestroyed', (event) => {
        this.deleteSubscriber((event as StreamEvent).stream);
        setTimeout(() => {
          this.checkSomeoneShareScreen();
        }, 20);
        event.preventDefault();
        this.updateLayout();
      });
    }
  }

  /**
   * Messaggi sessione
   */
  subscribeToSessionMessages() {
    const { timer } = this.state;
    let data;
    if (this.state.session) {
      (this.state.session as Session).on('signal', async (event) => {
        switch ((event as SignalEvent).type) {
          case 'signal:closedRoom':
            //Caccio Tutti Fuori
            this.leaveSession(true);
            break;
          case 'signal:startRecorded':
            timer.start();
            timer.addEventListener('secondsUpdated', this.tick);
            this.mostraNotifica(
              'Registrazione Attivata',
              'message',
              '',
              'assets/images/rec.svg',
              false
            );
            await this.setStateAsync({
              recAttiva: 'true'
            });
            break;
          case 'signal:stopRecorded':
            timer.stop();
            this.mostraNotifica(
              'Registrazione Disattivata',
              'message',
              '',
              'assets/images/rec.svg',
              false
            );
            await this.setStateAsync({
              recAttiva: 'false'
            });
            break;
          case 'signal:userLatency':
            if ((event as SignalEvent).data) {
              data = JSON.parse((event as SignalEvent).data as string);
              const latencyUsers = this.state.latencyUsers;
              latencyUsers.set(data.id, data.time);
              await this.setStateAsync({
                latencyUsers: latencyUsers
              });
            }
            break;
          case 'signal:newDocsUploaded':
            if ((event as SignalEvent).data) {
              logger('Nuovo Documento', (event as SignalEvent).data as string);
              //Aggiungo il documento appena caricato
              const elencoD = this.state.docCondivisi;
              elencoD.splice(0, 0, JSON.parse((event as SignalEvent).data as string));
              await this.setStateAsync({
                docCondivisi: elencoD
              });
              this.mostraNotifica(
                'Documento Condiviso',
                'file',
                JSON.parse((event as SignalEvent).data as string),
                'assets/images/document.svg',
                false
              );
            }
            break;
          case 'signal:newMeasurement':
            if ((event as SignalEvent).data) {
              logger('Nuova Misurazione', (event as SignalEvent).data as string);
              //Aggiungo la misurazione appena inviata
              const elencoM = this.state.misurazioniDevice;
              const item = JSON.parse((event as SignalEvent).data as string);
              const newArray = [item].concat(elencoM);
              await this.setStateAsync({
                misurazioniDevice: newArray
              });
              this.mostraNotifica(
                'Nuova Misurazione',
                'measure',
                JSON.parse((event as SignalEvent).data as string),
                'assets/images/health.svg',
                false
              );
            }
            break;
          case 'signal:userExit':
            if ((event as SignalEvent).data) {
              data = JSON.parse((event as SignalEvent).data as string);
              console.log(data);
              const streamId = data.streamid;
              const elenco = this.state.subscribers.filter(
                (user) => user.streamManager && user.streamManager.stream.streamId !== streamId
              );
              await this.setStateAsync({
                subscribers: elenco
              });
              const el = document.getElementById('remoteUsers-' + streamId);
              if (el !== null && el !== undefined) {
                const layout = document.getElementById('layout');
                if (layout !== null) {
                  layout.removeChild(el);
                }
              }
              this.updateLayout();
            }
            break;
          case 'signal:stetoscopeON':
            logger('Stetoscope Active', '');
            this.mostraNotifica(
              'Stetoscopio Attivato',
              'static',
              "L'audio operatore è disabilitato temporaneamente per una migliore auscultazione",
              'assets/images/mute.svg',
              true
            );
            break;
          case 'signal:stetoscopeOFF':
            logger('Stetoscope Disactive', '');
            this.nascondiNotifica();
            break;
          case 'signal:setIPWorkStation': //{"ip": ""}
            if ((event as SignalEvent).data) {
              data = JSON.parse((event as SignalEvent).data as string);
              console.log(data);
              this.setIPWorkStation(data.ip);
            }
            break;
          case 'signal:stetoOFF':
            this.closeStetoscopio();
            break;
          case 'signal:refreshCamera':
            this.refreshCamera();
            break;
          case 'signal:sendMeasurement': //{"device": "", "misurazioni": {}}
            if ((event as SignalEvent).data) {
              data = JSON.parse((event as SignalEvent).data as string);
              console.log(data);
              this.sendMeasurement(data.device, data.misurazioni);
            }
            break;
          default:
            break;
        }
      });
    }
  }

  /**
   * Controllore se è cambiato lo stato di un Partecipante (Audio, Video, Condivisione Schermo)
   */
  subscribeToUserChanged() {
    if (this.state.session) {
      this.state.session.on('signal:userChanged', async (event) => {
        const item = event as SignalEvent;
        if (item) {
          const remoteUsers = this.state.subscribers;
          remoteUsers.forEach((user) => {
            if (
              item.from &&
              item.data &&
              user.connectionId === (item.from as Connection).connectionId
            ) {
              const data = JSON.parse(item.data as string);
              if (data.isAudioActive !== undefined) {
                user.audioActive = data.isAudioActive;
              }
              if (data.isVideoActive !== undefined) {
                user.videoActive = data.isVideoActive;
              }
              if (data.isScreenShareActive !== undefined) {
                user.screenShareActive = data.isScreenShareActive;
              }
            }
          });
          await this.setStateAsync({
            subscribers: remoteUsers
          });
          this.checkSomeoneShareScreen();
        }
      });
    }
  }

  /**
   * Controllo se è Mobile
   */
  checkMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  /**
   * Funzione di aggiornamento del layout dei video
   */
  updateLayout() {
    setTimeout(() => {
      this.refreshLayout();
    }, 20);
  }

  /**
   * Funzione di Aggiornamento delle schermate video
   */
  refreshLayout() {
    const isScreenShared =
      this.state.subscribers.some((user) => user.screenShareActive) || localUser.screenShareActive;
    const numeroPartecipanti = this.state.subscribers.length + 1;
    let streamVideo, videoName;
    if (numeroPartecipanti === 1) {
      if (localUser.screenShareActive) {
        if (localUser.streamManager && localUser.streamManager.stream !== undefined) {
          if (localUser.streamManager.stream.streamId !== undefined) {
            streamVideo = document.getElementById(
              'video-' + localUser.streamManager.stream.streamId
            );
            if (streamVideo !== null) {
              streamVideo.style.objectFit = typeVideo;
            }
            videoName = document.getElementById('name-' + localUser.streamManager.stream.streamId);
            if (videoName !== null) {
              videoName.style.display = 'block';
            }
          }
        } else {
          streamVideo = document.getElementById('video-undefined');
          if (streamVideo != null) {
            streamVideo.style.objectFit = typeVideo;
          }
          videoName = document.getElementById('name-undefined');
          if (videoName !== null) {
            videoName.style.display = 'block';
          }
        }
      } else {
        if (localUser !== undefined && localUser != null) {
          if (localUser.streamManager !== undefined && localUser.streamManager != null) {
            if (
              localUser.streamManager.stream !== undefined &&
              localUser.streamManager.stream != null
            ) {
              if (
                localUser.streamManager.stream.streamId !== undefined &&
                localUser.streamManager.stream.streamId != null
              ) {
                streamVideo = document.getElementById(
                  'video-' + localUser.streamManager.stream.streamId
                );
                if (streamVideo !== null) {
                  streamVideo.style.objectFit = typeVideo;
                }
                videoName = document.getElementById(
                  'name-' + localUser.streamManager.stream.streamId
                );
                if (videoName !== null) {
                  videoName.style.display = 'block';
                }
              }
            }
          } else {
            streamVideo = document.getElementById('video-undefined');
            if (streamVideo != null) {
              streamVideo.style.objectFit = typeVideo;
            }
            videoName = document.getElementById('name-undefined');
            if (videoName !== null) {
              videoName.style.display = 'block';
            }
          }

          streamVideo = document.getElementById('video-localUser');
          if (streamVideo != null) {
            streamVideo.style.objectFit = typeVideo;
          }
          videoName = document.getElementById('name-localUser');
          if (videoName !== null) {
            videoName.style.display = 'block';
          }
        }
      }

      const videos = document.getElementsByClassName(
        'OT_root OT_publisher'
      ) as HTMLCollectionOf<HTMLElement>;
      Array.from(videos).forEach((el) => {
        el.style.width = '100%';
        el.style.height = '100%';
        el.style.float = 'left';
      });
    } else {
      if (isScreenShared) {
        if (localUser.streamManager && localUser.screenShareActive) {
          //Aggiusto Condivisione Schermo Locale
          streamVideo = document.getElementById('video-' + localUser.streamManager.stream.streamId);
          if (streamVideo !== null) {
            streamVideo.style.objectFit = typeVideo;
          }

          videoName = document.getElementById('name-' + localUser.streamManager.stream.streamId);
          if (videoName !== null) {
            videoName.style.display = 'block';
          }

          const videos = document.getElementsByClassName(
            'OT_root OT_publisher'
          ) as HTMLCollectionOf<HTMLElement>;
          Array.from(videos).forEach((el) => {
            if (el.id === 'localUser') {
              if (window.matchMedia('(orientation: portrait)').matches) {
                el.style.width = '100%';
                el.style.height = '100%';
                el.style.float = 'left';
              } else {
                el.style.width = '80%';
                el.style.height = '100%';
                el.style.float = 'left';
              }
            }
          });

          //Dimensioni tutti gli altri al lato destro
          Array.from(this.state.subscribers).forEach((sub) => {
            if (sub.streamManager) {
              const video = document.getElementById(
                'remoteUsers-' + sub.streamManager.stream.streamId
              );
              streamVideo = document.getElementById('video-' + sub.streamManager.stream.streamId);
              videoName = document.getElementById('name-' + sub.streamManager.stream.streamId);
              if (streamVideo !== null && videoName !== null) {
                if (window.matchMedia('(orientation: portrait)').matches) {
                  streamVideo.style.objectFit = typeVideo;
                  if (video !== null) {
                    video.style.width = '100%';
                    video.style.height = 'calc(100% / 2 )';
                    video.style.float = 'right';
                  }
                } else {
                  if (videoName != null) {
                    videoName.style.display = 'block';
                  }
                  streamVideo.style.objectFit = typeVideo;
                  if (video !== null) {
                    video.style.width = '20%';
                    video.style.height = 'calc(100% / 4 )';
                    video.style.float = 'right';
                  }
                }
              }
            }
          });
        } else {
          //Dimensiono Utente locale
          if (localUser.streamManager) {
            streamVideo = document.getElementById(
              'video-' + localUser.streamManager.stream.streamId
            );
            videoName = document.getElementById('name-' + localUser.streamManager.stream.streamId);
            if (videoName !== null) {
              videoName.style.display = 'block';
            }
            if (streamVideo !== null) {
              streamVideo.style.objectFit = typeVideo;
            }
            const videos = document.getElementsByClassName(
              'OT_root OT_publisher'
            ) as HTMLCollectionOf<HTMLElement>;
            Array.from(videos).forEach((el) => {
              if (el.id === 'localUser') {
                videoName = document.getElementById('name-localUser');
                if (window.matchMedia('(orientation: portrait)').matches) {
                  el.style.width = '100%';
                  el.style.height = 'calc(100% / 2)';
                  el.style.float = 'right';
                } else {
                  if (videoName != null) {
                    videoName.style.display = 'block';
                  }
                  el.style.width = '20%';
                  el.style.height = 'calc(100% / 4)';
                  el.style.float = 'right';
                }
              }
            });
          }

          //Trovo chi sta condividendo
          const layout = document.getElementById('layout');
          if (layout !== null) {
            const divs = layout.getElementsByTagName('div');
            const partecipante = this.state.subscribers.filter(
              (user) => user.screenShareActive === true
            );
            if (partecipante[0].streamManager) {
              const videoDaSpostare = document.getElementById(
                'remoteUsers-' + partecipante[0].streamManager.stream.streamId
              );
              if (videoDaSpostare !== null) {
                layout.insertBefore(videoDaSpostare, divs[0]);
              }
            }
          }

          //Dimensiono tutti gli utenti remote
          Array.from(this.state.subscribers).forEach((sub) => {
            if (sub.streamManager) {
              const video = document.getElementById(
                'remoteUsers-' + sub.streamManager.stream.streamId
              );
              if (sub.screenShareActive) {
                videoName = document.getElementById('name-' + sub.streamManager.stream.streamId);
                if (videoName !== null) {
                  videoName.style.display = 'block';
                }

                if (video != null) {
                  streamVideo = document.getElementById(
                    'video-' + sub.streamManager.stream.streamId
                  );
                  if (streamVideo !== null) {
                    if (window.matchMedia('(orientation: portrait)').matches) {
                      streamVideo.style.objectFit = typeVideo;
                      video.style.width = '100%';
                      video.style.height = 'calc(100% / 2)';
                      video.style.float = 'left';
                    } else {
                      streamVideo.style.objectFit = typeVideo;
                      video.style.width = '80%';
                      video.style.height = '100%';
                      video.style.float = 'left';
                    }
                  }
                }
              } else {
                streamVideo = document.getElementById('video-' + sub.streamManager.stream.streamId);
                videoName = document.getElementById('name-' + sub.streamManager.stream.streamId);
                if (video !== null && streamVideo !== null && videoName !== null) {
                  if (window.matchMedia('(orientation: portrait)').matches) {
                    streamVideo.style.objectFit = typeVideo;
                    video.style.width = '100%';
                    video.style.height = 'calc(100% / 2 )';
                    video.style.float = 'right';
                  } else {
                    videoName.style.display = 'block';
                    streamVideo.style.objectFit = typeVideo;
                    video.style.width = '20%';
                    video.style.height = 'calc(100% / 4 )';
                    video.style.float = 'right';
                  }
                }
              }
            }
          });
        }
      } else {
        //Resetto tutte le webcam
        if (localUser.streamManager) {
          if (localUser.streamManager.stream.streamId !== undefined) {
            streamVideo = document.getElementById(
              'video-' + localUser.streamManager.stream.streamId
            );
            videoName = document.getElementById('name-' + localUser.streamManager.stream.streamId);
            if (videoName != null) {
              videoName.style.display = 'block';
            } else {
              const videoNameLocal = document.getElementById('name-localUser');
              if (videoNameLocal != null) {
                videoNameLocal.style.display = 'block';
              }
            }
            if (streamVideo != null) {
              if (window.matchMedia('(orientation: portrait)').matches) {
                streamVideo.style.objectFit = typeVideo;
              } else {
                streamVideo.style.objectFit = typeVideo;
              }
            }
          }
        } else {
          streamVideo = document.getElementById('video-undefined');
          videoName = document.getElementById('name-localUser');
          if (streamVideo !== null && videoName !== null) {
            videoName.style.display = 'block';
            if (window.matchMedia('(orientation: portrait)').matches) {
              streamVideo.style.objectFit = typeVideo;
            } else {
              streamVideo.style.objectFit = typeVideo;
            }
          }
        }

        Array.from(this.state.subscribers).forEach((sub) => {
          if (sub.streamManager) {
            streamVideo = document.getElementById('video-' + sub.streamManager.stream.streamId);
            videoName = document.getElementById('name-' + sub.streamManager.stream.streamId);
            if (videoName != null) {
              videoName.style.display = 'block';
            }
            if (streamVideo !== null) {
              if (window.matchMedia('(orientation: portrait)').matches) {
                streamVideo.style.objectFit = typeVideo;
              } else {
                streamVideo.style.objectFit = typeVideo;
              }
            }
          }
        });

        const videos = document.getElementsByClassName(
          'OT_root OT_publisher'
        ) as HTMLCollectionOf<HTMLElement>;
        switch (numeroPartecipanti) {
          case 2:
            Array.from(videos).forEach((el) => {
              if (window.matchMedia('(orientation: portrait)').matches) {
                el.style.width = '100%';
                el.style.height = 'calc(100% / 2)';
                el.style.float = 'left';
              } else {
                el.style.width = 'calc(100% / 2)';
                el.style.height = '100%';
                el.style.float = 'left';
              }
            });
            break;
          case 3:
          case 4:
            Array.from(videos).forEach((el) => {
              if (window.matchMedia('(orientation: portrait)').matches) {
                el.style.width = 'calc(100% / 2)';
                el.style.height = 'calc(100% / 2)';
                el.style.float = 'left';
              } else {
                el.style.width = 'calc(100% / 2)';
                el.style.height = 'calc(100% / 2)';
                el.style.float = 'left';
              }
            });
            break;
          case 5:
          case 6:
            Array.from(videos).forEach((el) => {
              if (window.matchMedia('(orientation: portrait)').matches) {
                el.style.width = 'calc(100% / 2)';
                el.style.height = 'calc(100% / 3)';
                el.style.float = 'left';
              } else {
                el.style.width = 'calc(100% / 3)';
                el.style.height = 'calc(100% / 2)';
                el.style.float = 'left';
              }
            });
            break;
          case 7:
          case 8:
            Array.from(videos).forEach((el) => {
              if (window.matchMedia('(orientation: portrait)').matches) {
                el.style.width = 'calc(100% / 2)';
                el.style.height = 'calc(100% / 4)';
                el.style.float = 'left';
              } else {
                el.style.width = 'calc(100% / 4)';
                el.style.height = 'calc(100% / 2)';
                el.style.float = 'left';
              }
            });
            break;
          default:
            break;
        }
      }
    }

    this.autoPlayAll();
  }

  async sendSignalUserChanged(data: {
    isAudioActive?: boolean;
    isVideoActive?: boolean;
    nickname?: string;
    isScreenShareActive?: boolean;
  }) {
    const signalOptions: SignalOptions = {
      data: JSON.stringify(data),
      type: 'userChanged'
    };
    await this.sendMessage(signalOptions);
  }

  /**
   * Cambio Camera
   */
  async switchCamera() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    console.log(devices);
    const videoDevices = devices.filter(
      (device) => device.kind === 'videoinput' && device.deviceId !== localUser.videoSource.deviceId
    );

    const mediaStream = await this.OV.getUserMedia({
      audioSource: localUser.audioSource.deviceId,
      videoSource: videoDevices[0].deviceId,
      mirror: !this.state.isFrontCamera
    });

    if (getMobileOperatingSystem() !== 'PC') {
      console.log('I am Mobile', localUser);
      //Devo usare lo swich con la distruzione e la ricostruzione
      if (this.state.session) {
        const isMuted = localUser.muted;
        this.state.session.unpublish(localUser.streamManager as Publisher);
        if (this.OV && localUser.audioSource) {
          const publisher = this.OV.initPublisher('', {
            audioSource: localUser.audioSource.deviceId,
            videoSource: videoDevices[0].deviceId,
            mirror: !this.state.isFrontCamera
          });
          localUser.videoSource = videoDevices[0];
          localUser.streamManager = publisher;
          localUser.muted = isMuted;
          await this.setStateAsync({
            publisher: publisher
          });
        }
        this.state.session.publish(localUser.streamManager as Publisher);
        await this.setStateAsync({
          isFrontCamera: !this.state.isFrontCamera
        });
      }
    } else {
      console.log('I am PC', localUser);
      const myTrack = mediaStream.getVideoTracks()[0];
      (localUser.streamManager as Publisher).replaceTrack(myTrack);
      localUser.videoSource = videoDevices[0];
      await this.setStateAsync({
        localUser
      });
    }
  }

  /**
   * Refresh Camera Camera
   */
  async refreshCamera() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    if (localUser.videoSource) {
      const videoDevices = devices.filter(
        (device) =>
          device.kind === 'videoinput' && device.deviceId === localUser.videoSource.deviceId
      );
      if (this.state.session && this.OV) {
        this.state.session.unpublish(localUser.streamManager as Publisher);
        const publisher = this.OV.initPublisher('', {
          audioSource: localUser.audioSource.deviceId,
          videoSource: videoDevices[0].deviceId,
          mirror: false
        });
        localUser.videoSource = videoDevices[0];
        localUser.streamManager = publisher;
        await this.setStateAsync({
          publisher: publisher
        });
        this.state.session.publish(localUser.streamManager);
        await this.setStateAsync({
          isFrontCamera: true
        });
      }
    }
  }

  /**
   * Creazione Messaggio di Misurazione e invio
   * Utilizzato da android per inviare le misure nella sessione
   */
  async sendMeasurement(
    device: string,
    misurazioni: { key: string; value: string; unit?: string }[]
  ) {
    await this.mostraProgressDialog('Invio Misurazione in corso...');
    const data: SendMeasureInput = {
      id: 'misurazione',
      device: device,
      misurazioni: misurazioni,
      timestamp: new Date().getTime()
    };
    if (this.state.datiStanza && this.state.partecipante) {
      const response = await insertMeasure(data);
      if (response.status === 200) {
        await this.nascondiProgressDialog();
      } else {
        await this.nascondiProgressDialog();
        await this.mostraDialog(response.object as string, 'error');
      }
    }
  }

  async onPressDevice(device: string) {
    if (device === 'camera1' || device === 'camera2' || device === 'camera3') {
      this.executeAvviaIPCamera(device);
    } else if (device === 'stetoscopio') {
      this.avviaStetoscopio();
    } else {
      if (this.state.datiStanza) {
        const idEvent = this.state.datiStanza.roomId.split('|')[1];
        let idAssistito = 0;
        let cognomeAssistito = '';
        let nomeAssistito = '';
        let operatore = '';
        this.state.datiStanza.partecipanti.forEach((partecipante) => {
          if (partecipante.username.toLowerCase().indexOf('ass') > -1) {
            idAssistito = parseInt(partecipante.username.split('_')[1], 10);
            cognomeAssistito = partecipante.cognome;
            nomeAssistito = partecipante.nome;
          }
        });

        this.state.datiStanza.partecipanti.forEach((partecipante) => {
          if (
            this.state.datiStanza &&
            partecipante.username.toLowerCase() === this.state.datiStanza.idModeratore.toLowerCase()
          ) {
            operatore = partecipante.cognome + ' ' + partecipante.nome;
          }
        });

        const domain = this.state.datiStanza.domain.replace('http://', 'https://');
        const dataNascitaAssistito = this.state.datiStanza.dataNascitaAssistito;

        logger('Premuto ', device);

        if (
          navigator.userAgent.indexOf('APP_SMARTLEA') > -1 ||
          navigator.userAgent.indexOf('APP_CUREDOM') > -1
        ) {
          switch (device.toLowerCase()) {
            case 'ossimetro':
              if (navigator.userAgent.indexOf('GECKOVIEW') > -1) {
                const message = {
                  type: 'startOssimetro',
                  response: {
                    idEvent,
                    idAssistito,
                    domain
                  }
                };
                window.postMessage(message, '*');
              } else {
                if ((window as any).JSInterface) {
                  (window as any).JSInterface.startOssimetro(idEvent, idAssistito, domain);
                }
              }
              break;
            case 'sfigmomanometro':
              if (navigator.userAgent.indexOf('GECKOVIEW') > -1) {
                const message = {
                  type: 'startSfigmomanometro',
                  response: {
                    idEvent,
                    idAssistito,
                    domain
                  }
                };
                window.postMessage(message, '*');
              } else {
                if ((window as any).JSInterface) {
                  (window as any).JSInterface.startSfigmomanometro(idEvent, idAssistito, domain);
                }
              }
              break;
            case 'bilancia':
              if (navigator.userAgent.indexOf('GECKOVIEW') > -1) {
                const message = {
                  type: 'startBilancia',
                  response: {
                    idEvent,
                    idAssistito,
                    domain
                  }
                };
                window.postMessage(message, '*');
              } else {
                if ((window as any).JSInterface) {
                  (window as any).JSInterface.startBilancia(idEvent, idAssistito, domain);
                }
              }
              break;
            case 'glucometro':
              if (navigator.userAgent.indexOf('GECKOVIEW') > -1) {
                const message = {
                  type: 'startGlucometro',
                  response: {
                    idEvent,
                    idAssistito,
                    domain
                  }
                };
                window.postMessage(message, '*');
              } else {
                if ((window as any).JSInterface) {
                  (window as any).JSInterface.startGlucometro(idEvent, idAssistito, domain);
                }
              }
              break;
            case 'termometro':
              if (navigator.userAgent.indexOf('GECKOVIEW') > -1) {
                const message = {
                  type: 'startTermometro',
                  response: {
                    idEvent,
                    idAssistito,
                    domain
                  }
                };
                window.postMessage(message, '*');
              } else {
                if ((window as any).JSInterface) {
                  (window as any).JSInterface.startTermometro(idEvent, idAssistito, domain);
                }
              }
              break;
            case 'spirometro':
              if (this.state.datiStanza.infoAssistito !== undefined) {
                if (navigator.userAgent.indexOf('GECKOVIEW') > -1) {
                  const message = {
                    type: 'startSpirometro',
                    response: {
                      idEvent,
                      idAssistito,
                      domain,
                      cognomeAssistito,
                      nomeAssistito,
                      dataNascitaAssistito,
                      infoAssistito: this.state.datiStanza.infoAssistito
                    }
                  };
                  window.postMessage(message, '*');
                } else {
                  if ((window as any).JSInterface) {
                    (window as any).JSInterface.startSpirometro(
                      idEvent,
                      idAssistito,
                      domain,
                      cognomeAssistito,
                      nomeAssistito,
                      dataNascitaAssistito,
                      this.state.datiStanza.infoAssistito
                    );
                  }
                }
              } else {
                if ((window as any).JSInterface) {
                  (window as any).JSInterface.startSpirometro(
                    idEvent,
                    idAssistito,
                    domain,
                    cognomeAssistito,
                    nomeAssistito,
                    dataNascitaAssistito
                  );
                }
              }
              break;
            case 'ecg':
              if ((window as any).JSInterface) {
                (window as any).JSInterface.startElettrocardiografo(
                  idEvent,
                  idAssistito,
                  nomeAssistito,
                  cognomeAssistito,
                  dataNascitaAssistito,
                  operatore,
                  this.state.datiStanza.infoAssistito,
                  domain
                );
              }
              break;
            default:
              break;
          }
        } else {
          switch (device.toLowerCase()) {
            case 'ossimetro':
              await this.setStateAsync({
                openInputOssimetro: true,
                openInputSfigmo: false,
                openInputBilancia: false,
                openInputGlucometro: false,
                openInputTermometro: false
              });
              break;
            case 'sfigmomanometro':
              await this.setStateAsync({
                openInputOssimetro: false,
                openInputSfigmo: true,
                openInputBilancia: false,
                openInputGlucometro: false,
                openInputTermometro: false
              });
              break;
            case 'bilancia':
              await this.setStateAsync({
                openInputOssimetro: false,
                openInputSfigmo: false,
                openInputBilancia: true,
                openInputGlucometro: false,
                openInputTermometro: false
              });
              break;
            case 'glucometro':
              await this.setStateAsync({
                openInputOssimetro: false,
                openInputSfigmo: false,
                openInputBilancia: false,
                openInputGlucometro: true,
                openInputTermometro: false
              });
              break;
            case 'termometro':
              await this.setStateAsync({
                openInputOssimetro: false,
                openInputSfigmo: false,
                openInputBilancia: false,
                openInputGlucometro: false,
                openInputTermometro: true
              });
              break;
            default:
              break;
          }
        }
      }
    }
  }

  /**
   * Chiamata servizio insert web measure
   */
  async executeSendMeasure({ misurazione, domain, idEvent, idAssistito }: SendWebMeasureInput) {
    const response = await insertWebMeasure({
      misurazione,
      domain,
      idEvent,
      idAssistito
    });
    if (response.status === 200) {
      await this.nascondiProgressDialog();
    } else {
      await this.nascondiProgressDialog();
      await this.mostraDialog(response.object as string, 'error');
    }
  }

  /**
   * Chiude il Dialog di input manuale
   */
  async closeInputDeviceOssimetro() {
    await this.setStateAsync({
      openInputOssimetro: false
    });
  }

  async sendInputDeviceOssimetro(spo2: string, pr: string) {
    console.log(spo2);
    console.log(pr);
    await this.setStateAsync({
      openInputOssimetro: false
    });
    if (this.state.datiStanza && this.state.partecipante) {
      //Creazione misurazione
      await this.mostraProgressDialog('Invio Misurazione in corso...');
      const domain = this.state.datiStanza.domain;
      let idAssistito = 0;
      this.state.datiStanza.partecipanti.forEach((partecipante) => {
        if (partecipante.username.toLowerCase().indexOf('ass') > -1) {
          idAssistito = parseInt(partecipante.username.split('_')[1], 10);
        }
      });
      const idEvent = this.state.datiStanza.roomId.split('|')[1];
      const misurazione: SendMeasureInput = {
        id: 'misurazione',
        device: 'ossimetro',
        misurazioni: [
          {
            key: 'SPO2',
            value: spo2,
            unit: '%'
          },
          {
            key: 'PR',
            value: pr,
            unit: 'bpm'
          }
        ],
        timestamp: new Date().getTime()
      };
      await this.executeSendMeasure({
        misurazione,
        domain,
        idEvent,
        idAssistito
      });
    }
  }

  /**
   * Chiude il Dialog di input manuale
   */
  async closeInputDeviceSfigmo() {
    await this.setStateAsync({
      openInputSfigmo: false
    });
  }

  /**
   * Chiude il Dialog e invia misurazione
   */
  async sendInputDeviceSfigmo(sys: string, dia: string) {
    console.log(sys);
    console.log(dia);
    await this.setStateAsync({
      openInputSfigmo: false
    });
    //Creazione misurazione
    await this.mostraProgressDialog('Invio Misurazione in corso...');
    if (this.state.datiStanza && this.state.partecipante) {
      const domain = this.state.datiStanza.domain;
      let idAssistito = 0;
      this.state.datiStanza.partecipanti.forEach((partecipante) => {
        if (partecipante.username.toLowerCase().indexOf('ass') > -1) {
          idAssistito = parseInt(partecipante.username.split('_')[1], 10);
        }
      });
      const idEvent = this.state.datiStanza.roomId.split('|')[1];
      const misurazione = {
        id: 'misurazione',
        device: 'sfigmomanometro',
        misurazioni: [
          {
            key: 'SYS',
            value: sys,
            unit: 'mmHg'
          },
          {
            key: 'DIA',
            value: dia,
            unit: 'mmHg'
          }
        ],
        timestamp: new Date().getTime()
      };
      await this.executeSendMeasure({
        misurazione,
        domain,
        idEvent,
        idAssistito
      });
    }
  }

  /**
   * Chiude il Dialog di input manuale
   */
  async closeInputDeviceBilancia() {
    await this.setStateAsync({
      openInputBilancia: false
    });
  }

  /**
   * Chiude il Dialog e invia misurazione
   */
  async sendInputDeviceBilancia(peso: string) {
    console.log(peso);
    await this.setStateAsync({
      openInputBilancia: false
    });
    if (this.state.partecipante && this.state.datiStanza) {
      //Creazione misurazione
      await this.mostraProgressDialog('Invio Misurazione in corso...');
      const domain = this.state.datiStanza.domain;
      let idAssistito = 0;
      this.state.datiStanza.partecipanti.forEach((partecipante) => {
        if (partecipante.username.toLowerCase().indexOf('ass') > -1) {
          idAssistito = parseInt(partecipante.username.split('_')[1], 10);
        }
      });
      const idEvent = this.state.datiStanza.roomId.split('|')[1];
      const misurazione = {
        id: 'misurazione',
        device: 'bilancia',
        misurazioni: [
          {
            key: 'PESO',
            value: peso,
            unit: 'Kg'
          }
        ],
        timestamp: new Date().getTime()
      };
      await this.executeSendMeasure({
        misurazione,
        domain,
        idEvent,
        idAssistito
      });
    }
  }

  /**
   * Chiude il Dialog di input manuale
   */
  async closeInputDeviceGlucometro() {
    await this.setStateAsync({
      openInputGlucometro: false
    });
  }

  /**
   * Chiude il Dialog e invia misurazione
   */
  async sendInputDeviceGlucometro(glicemia: string) {
    console.log(glicemia);
    await this.setStateAsync({
      openInputGlucometro: false
    });
    if (this.state.partecipante && this.state.datiStanza) {
      //Creazione misurazione
      await this.mostraProgressDialog('Invio Misurazione in corso...');
      const domain = this.state.datiStanza.domain;
      let idAssistito = 0;
      this.state.datiStanza.partecipanti.forEach((partecipante) => {
        if (partecipante.username.toLowerCase().indexOf('ass') > -1) {
          idAssistito = parseInt(partecipante.username.split('_')[1], 10);
        }
      });
      const idEvent = this.state.datiStanza.roomId.split('|')[1];
      const misurazione = {
        id: 'misurazione',
        device: 'glucometro',
        misurazioni: [
          {
            key: 'GLI',
            value: glicemia,
            unit: 'mg/dL'
          }
        ],
        timestamp: new Date().getTime()
      };
      await this.executeSendMeasure({
        misurazione,
        domain,
        idEvent,
        idAssistito
      });
    }
  }

  /**
   * Chiude il Dialog di input manuale
   */
  async closeInputDeviceTermometro() {
    await this.setStateAsync({
      openInputTermometro: false
    });
  }

  /**
   * Chiude il Dialog e invia misurazione
   */
  async sendInputDeviceTermometro(temp: string) {
    console.log(temp);
    await this.setStateAsync({
      openInputTermometro: false
    });
    if (this.state.partecipante && this.state.datiStanza) {
      //Creazione misurazione
      await this.mostraProgressDialog('Invio Misurazione in corso...');
      const domain = this.state.datiStanza.domain;
      let idAssistito = 0;
      this.state.datiStanza.partecipanti.forEach((partecipante) => {
        if (partecipante.username.toLowerCase().indexOf('ass') > -1) {
          idAssistito = parseInt(partecipante.username.split('_')[1], 10);
        }
      });
      const idEvent = this.state.datiStanza.roomId.split('|')[1];
      const misurazione = {
        id: 'misurazione',
        device: 'termometro',
        misurazioni: [
          {
            key: 'TEMP',
            value: temp,
            unit: 'C'
          }
        ],
        timestamp: new Date().getTime()
      };
      await this.executeSendMeasure({
        misurazione,
        domain,
        idEvent,
        idAssistito
      });
    }
  }

  /**
   * Messaggio Avvio Registrazione
   */
  async startRecording() {
    logger('Start Recording', '');
    await this.mostraDialog('Vuoi Avviare la Registrazione?', 'warning', [
      {
        title: 'si',
        onItemPress: this.avviaRegistrazione.bind(this)
      },
      {
        title: 'no',
        onItemPress: this.nascondiDialog.bind(this)
      }
    ]);
  }

  async stopRecording() {
    logger('Stop Recording', '');
    await this.mostraDialog('Vuoi Fermare la Registrazione?', 'warning', [
      {
        title: 'si',
        onItemPress: this.fermaRegistrazione.bind(this)
      },
      {
        title: 'no',
        onItemPress: this.nascondiDialog.bind(this)
      }
    ]);
  }

  /**
   * Chiamata REST per avviare la webcam
   */
  async executeAvviaIPCamera(typeCamera: string) {
    if (this.state.ipWorkStation !== '') {
      let url = '';
      let nomeCamera = '';
      switch (typeCamera) {
        case 'camera1':
          url = 'rtsp://usercam:InnVCam219218@' + this.state.ipWorkStation + ':8888/gen';
          nomeCamera = 'Camera Generica';
          break;
        case 'camera2':
          url = 'rtsp://usercam:InnVCam219218@' + this.state.ipWorkStation + ':8888/der';
          nomeCamera = 'Camera Dermatoscopica';
          break;
        case 'camera3':
          url = 'rtsp://usercam:InnVCam219218@' + this.state.ipWorkStation + ':8888/oto';
          nomeCamera = 'Camera Otoscopica';
          break;

        default:
          break;
      }
      if (url !== '' && nomeCamera !== '' && this.state.partecipante) {
        await this.nascondiDialog();
        await this.mostraProgressDialog('Attivazione Camera...');
        const response = await startIPCamera(url, nomeCamera);
        logger('startIPCamera', response);
        if (response.status === 200 && typeof response.object !== 'string') {
          await this.nascondiProgressDialog();
          await this.setStateAsync({
            idConnessione: response.object.id,
            cameraAttiva: true
          });
        } else {
          await this.nascondiProgressDialog();
          await this.mostraDialog(response.object as string, 'error');
        }
      }
    } else {
      await this.mostraDialog('IP Workstation non settato', 'error');
    }
  }

  /**
   * Chiamata REST per avviare lo stetoscopio
   */
  async avviaStetoscopio() {
    //Disabilito gli audio temporaneamente
    const elenco = this.state.subscribers;
    Array.from(elenco).forEach((sub) => {
      sub.muted = true;
    });
    await this.setStateAsync({
      subscribers: elenco
    });

    await this.nascondiDialog();
    await this.mostraProgressDialog('Attivazione Stetoscopio...');

    if (this.state.partecipante && this.state.datiStanza) {
      const response = await stetoscopeON();
      if (response.status === 200) {
        await this.nascondiProgressDialog();
        const idEvent = this.state.datiStanza.roomId.split('|')[1];

        let idAssistito;
        this.state.datiStanza.partecipanti.forEach((partecipante) => {
          if (partecipante.username.toLowerCase().indexOf('ass') > -1) {
            idAssistito = partecipante.username.split('_')[1];
          }
        });

        const domain = this.state.datiStanza.domain.replace('http://', 'https://');
        if (
          navigator.userAgent.indexOf('APP_SMARTLEA') > -1 ||
          navigator.userAgent.indexOf('APP_CUREDOM') > -1
        ) {
          if (navigator.userAgent.indexOf('GECKOVIEW') > -1) {
            const message = {
              type: 'startStetoscope',
              response: {
                idEvent,
                idAssistito,
                domain,
                roomId: this.state.partecipante.roomId,
                idPartecipante: this.state.partecipante.id
              }
            };
            window.postMessage(message, '*');
          } else {
            if ((window as any).JSInterface) {
              (window as any).JSInterface.startStetoscope(
                idEvent,
                idAssistito,
                domain,
                this.state.partecipante.roomId,
                this.state.partecipante.id
              );
            }
          }
        }
      } else {
        await this.nascondiProgressDialog();
        await this.mostraDialog(response.object, 'error');
      }
    }
  }

  /**
   * Chiamata REST per chiusura Stetoscopio
   */
  async closeStetoscopio() {
    //Ripristino Audio degli altri
    const elenco = this.state.subscribers;
    Array.from(elenco).forEach((sub) => {
      sub.muted = false;
    });
    await this.setStateAsync({
      subscribers: elenco
    });

    await this.nascondiDialog();
    await this.mostraProgressDialog('Disattivazione Stetoscopio...');

    if (this.state.partecipante) {
      const response = await stetoscopeOFF();

      if (response.status === 200) {
        await this.nascondiProgressDialog();
      } else {
        await this.nascondiProgressDialog();
        await this.mostraDialog(response.object, 'error');
      }
    }
  }

  /**
   * Apertura Popup Ascolto Audio
   */
  async openAudioPlayer(urlAudio: string) {
    //Disabilito gli audio temporaneamente
    const elenco = this.state.subscribers;
    Array.from(elenco).forEach((sub) => {
      sub.muted = true;
    });
    await this.setStateAsync({
      subscribers: elenco
    });

    await this.setStateAsync({
      openAudioStetoPlayer: true,
      urlAudio: urlAudio
    });
  }

  /**
   * Chiamata per chiusura ipcam
   */
  async closeIPCamera(idConnessione: string) {
    await this.nascondiDialog();
    await this.mostraProgressDialog('Disattivazione Camera...');

    if (this.state.partecipante) {
      const response = await stopIPCamera(idConnessione);
      if (response.status === 200) {
        await this.nascondiProgressDialog();
        await this.setStateAsync({
          idConnessione: '',
          cameraAttiva: false
        });
      } else {
        await this.nascondiProgressDialog();
        await this.mostraDialog(response.object, 'error');
      }
    }
  }

  /**
   * Chiamata REST per avviare la registrazione
   */
  async avviaRegistrazione() {
    await this.nascondiDialog();
    await this.mostraProgressDialog('Attivazione Registrazione...');
    if (this.state.partecipante) {
      const response = await startRecord();
      if (response.status === 200) {
        await this.nascondiProgressDialog();
      } else {
        await this.nascondiProgressDialog();
        await this.mostraDialog(response.object, 'error');
      }
    }
  }

  /**
   * Chiamata REST per fermare la registrazione
   */
  async fermaRegistrazione() {
    await this.nascondiDialog();
    await this.mostraProgressDialog('Disattivazione Registrazione...');
    if (this.state.partecipante) {
      const response = await stopRecord();
      if (response.status === 200) {
        await this.nascondiProgressDialog();
      } else {
        await this.nascondiProgressDialog();
        await this.mostraDialog(response.object, 'error');
      }
    }
  }

  /**
   * Invio messaggi nella sessione
   */
  async sendMessage(signalOptions: SignalOptions) {
    try {
      if (this.state.session && this.state.session.signal) {
        await this.state.session.signal(signalOptions);
      }
    } catch (error) {
      console.log('sendMessage', error);
    }
  }

  /**
   * Possibilità di visualizzare a tutto schermo la sessione
   */
  toggleFullscreen() {
    const document = window.document;
    const fs = document.getElementById('container');
    if (
      fs !== null &&
      !document.fullscreenElement &&
      !(document as any).mozFullScreenElement &&
      !(document as any).webkitFullscreenElement &&
      !(document as any).msFullscreenElement
    ) {
      if (fs.requestFullscreen) {
        fs.requestFullscreen();
      } else if ((fs as any).msRequestFullscreen) {
        (fs as any).msRequestFullscreen();
      } else if ((fs as any).mozRequestFullScreen) {
        (fs as any).mozRequestFullScreen();
      } else if ((fs as any).webkitRequestFullscreen) {
        (fs as any).webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        (document as any).msExitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitExitFullscreen) {
        (document as any).webkitExitFullscreen();
      }
    }
  }

  /**
   * Chiamata di Condivisione Schermo
   */
  async screenShare() {
    await this.setStateAsync({
      isFrontCamera: false
    });
    if (this.OV) {
      const publisher = this.OV.initPublisher(
        '',
        {
          videoSource: 'screen',
          audioSource: localUser.audioActive,
          publishAudio: localUser.audioActive,
          publishVideo: true,
          mirror: false
        },
        (error) => {
          if (error && error.name === 'SCREEN_SHARING_NOT_SUPPORTED') {
            alert('Il tuo Browser non supporta la condivisione schermo');
          } else if (error && error.name === 'SCREEN_EXTENSION_DISABLED') {
            alert('Occorre Abilitare Estensione del Browser');
          } else if (error && error.name === 'SCREEN_CAPTURE_DENIED') {
            alert('Selezionare tutto lo schermo o una finestra');
          }
        }
      );

      publisher.once('accessAllowed', () => {
        publisher.stream
          .getMediaStream()
          .getVideoTracks()[0]
          .addEventListener('ended', () => {
            logger('User pressed the "Stop sharing" button', '');
            this.stopScreenShare();
          });

        if (this.state.session) {
          this.state.session.unpublish(localUser.streamManager as Publisher);
          localUser.streamManager = publisher;
          this.state.session.publish(localUser.streamManager).then(async () => {
            localUser.screenShareActive = true;
            await this.setStateAsync({ localUser: localUser });
            await this.sendSignalUserChanged({
              isScreenShareActive: localUser.screenShareActive
            });
          });
        }
      });
      publisher.on('streamPlaying', async () => {
        this.updateLayout();
        publisher.videos[0].video.style.display = 'block';
        publisher.videos[0].video.style.opacity = '0.4';
        await this.setStateAsync({
          publisher: publisher
        });
      });
    }
  }
  /**
   * Ferma Condivisione Schermo e riattiva la webcam
   */
  async stopScreenShare() {
    if (this.state.publisher) {
      this.state.publisher.videos[0].video.style.display = 'block';
      this.state.publisher.videos[0].video.style.opacity = '1.0';
      await this.setStateAsync({
        publisher: undefined
      });
    }

    if (this.state.session) {
      this.state.session.unpublish(localUser.streamManager as Publisher);
    }
    this.connectWebCam();
  }

  /**
   * Controllore se un partecipante sta condividendo lo schermo
   */
  checkSomeoneShareScreen() {
    const isScreenShared =
      this.state.subscribers.some((user) => user.screenShareActive) || localUser.screenShareActive;
    logger(
      'checkSomeoneShareScreen',
      isScreenShared ? 'Condivisione Schermo' : 'Nessuna Condivisione Schermo'
    );
    this.updateLayout();
  }

  async exitSession() {
    logger('Exit Session', '');
    await this.mostraDialog('Vuoi Uscire?', 'warning', [
      {
        title: 'si',
        onItemPress: this.esciStanza.bind(this)
      },
      {
        title: 'no',
        onItemPress: this.nascondiDialog.bind(this)
      }
    ]);
  }

  async closeSession() {
    logger('Close Session', '');
    await this.mostraDialog(
      'Vuoi Chiudere la Sessione?\nLa stanza sarà chiusa e archiviata.',
      'warning',
      [
        {
          title: 'si',
          onItemPress: this.chiudiStanza.bind(this)
        },
        {
          title: 'no',
          onItemPress: this.nascondiDialog.bind(this)
        }
      ]
    );
  }

  /**
   * Chiamata per uscire dalla stanza
   */
  async esciStanza() {
    await this.nascondiDialog();
    await this.leaveSession();
  }

  /**
   * Chiamata REST per chiudere la stanza.
   */
  async chiudiStanza() {
    let result;
    await this.nascondiDialog();
    await this.mostraProgressDialog('Chiusura Stanza in corso...');

    if (this.state.partecipante && this.state.datiStanza) {
      const response = await closeStanza();
      if (response.status === 200) {
        await this.nascondiProgressDialog();
        //Setto la registrazione a false perchè chiusa dalla chiamata rest
        await this.setStateAsync({
          recAttiva: 'false'
        });
        result = this.state.datiStanza.roomId.split('|');
        const idStanza = result[1];
        if (
          navigator.userAgent.indexOf('APP_SMARTLEA') > -1 ||
          navigator.userAgent.indexOf('APP_CUREDOM') > -1 ||
          navigator.userAgent.indexOf('APP_SL_RN') > -1
        ) {
          if (navigator.userAgent.indexOf('AMBULATORIO') > -1) {
            this.leaveSession(true);
          } else {
            if ((window as any).JSInterface) {
              (window as any).JSInterface.chiudiAccesso(idStanza);
            }
          }
        } else {
          this.leaveSession(true);
        }
      } else {
        await this.nascondiProgressDialog();
        await this.mostraDialog(response.object as string, 'error');
      }
    }
  }
  async nascondiNotifica() {
    await this.setStateAsync({
      messageNotifica: '',
      typeNotifica: 'message',
      contentNotifica: '',
      showNotifica: false,
      progressNotifica: TIMERNOTIFICA,
      imageNotifica: 'assets/images/notify.svg'
    });
  }

  async mostraNotifica(
    message: string,
    type: 'doc' | 'measure' | 'message' | 'file' | 'static',
    content: string,
    image: string,
    indeterminate: boolean
  ) {
    clearInterval(this.state.countdownNotifica);
    this.nascondiNotifica();
    await this.setStateAsync({
      messageNotifica: message,
      typeNotifica: type,
      contentNotifica: content,
      showNotifica: true,
      progressNotifica: indeterminate ? -1 : TIMERNOTIFICA,
      imageNotifica: image
    });
    if (!indeterminate) {
      const countdown = setInterval(this.timerNotifica, 1000);
      await this.setStateAsync({
        countdownNotifica: countdown
      });
    }
  }

  async timerNotifica() {
    if (this.state.progressNotifica <= 0) {
      clearInterval(this.state.countdownNotifica);
      this.nascondiNotifica();
    }
    await this.setStateAsync({
      progressNotifica: this.state.progressNotifica - 1
    });
  }

  /**
   * Render Grafico schermata
   */
  render() {
    const _localUser = this.state.localUser;
    const partecipante = this.state.partecipante;
    const remotes = this.state.subscribers;
    return (
      <div className="container" id="container">
        {this.state.showToolbar ? (
          <ToolbarComponent
            timeValues={this.state.timeValues}
            user={_localUser as UserModel}
            partecipante={partecipante}
            remotes={remotes as UserModel[]}
            isFrontCamera={this.state.isFrontCamera}
            cams={this.state.numero_device}
            recAttiva={this.state.recAttiva}
            datistanza={this.state.datiStanza}
            docCondivisi={this.state.docCondivisi}
            misurazioniDevice={this.state.misurazioniDevice}
            ipWorkStation={this.state.ipWorkStation}
            cameraAttiva={this.state.cameraAttiva}
            camStatusChanged={this.camStatusChanged}
            micStatusChanged={this.micStatusChanged}
            screenShare={this.screenShare}
            stopScreenShare={this.stopScreenShare}
            toggleFullscreen={this.toggleFullscreen}
            leaveSession={this.exitSession}
            startRecording={this.startRecording}
            stopRecording={this.stopRecording}
            exitSession={this.exitSession}
            closeSession={this.closeSession}
            onPressDevice={this.onPressDevice}
            switchCamera={this.switchCamera}
            openAudioPlayer={this.openAudioPlayer}
          />
        ) : null}

        <div id="layout" className="bounds">
          {_localUser !== undefined && _localUser.streamManager !== undefined && (
            <div className="OT_root OT_publisher custom_class" id="localUser">
              <StreamComponent
                user={_localUser as UserModel}
                figura={this.state.partecipante.figura}
                identifier="localUser"
                data={_localUser.data as Partecipante}
                closeCamera={(connectionID) => this.closeIPCamera(connectionID)}
                datistanza={this.state.datiStanza}
                localUser={_localUser as UserModel}
                latencyUsers={this.state.latencyUsers}
                toogleSound={async () => {
                  _localUser.muted = !_localUser.muted;
                  await this.setStateAsync({
                    localUser: _localUser
                  });
                }}
              />
            </div>
          )}
          {this.state.subscribers.map((sub, i) => (
            <div
              key={i}
              className="OT_root OT_publisher custom_class"
              id={'remoteUsers-' + sub.streamManager.stream.streamId}>
              <StreamComponent
                user={sub as UserModel}
                figura={(sub.data as Partecipante).figura}
                identifier={sub.streamManager.stream.streamId}
                data={sub.data as Partecipante}
                closeCamera={(connectionID) => this.closeIPCamera(connectionID)}
                datistanza={this.state.datiStanza}
                localUser={_localUser as UserModel}
                latencyUsers={this.state.latencyUsers}
                toogleSound={async () => {
                  sub.muted = !sub.muted;
                  await this.setStateAsync({
                    subscribers: this.state.subscribers
                  });
                }}
              />
            </div>
          ))}
        </div>
        <MaterialDialog
          visible={this.state.showDialog}
          message={this.state.messageDialog}
          type={this.state.typeDialog}
          buttons={this.state.buttonsDialog}></MaterialDialog>
        <MaterialProgressDialog
          visible={this.state.showProgressDialog}
          message={this.state.messageProgress}></MaterialProgressDialog>
        <Notification
          datistanza={this.state.datiStanza}
          visible={this.state.showNotifica}
          message={this.state.messageNotifica}
          type={this.state.typeNotifica}
          content={this.state.contentNotifica}
          progress={this.state.progressNotifica}
          image={this.state.imageNotifica}
          openSteto={(value) => this.openAudioPlayer(value)}></Notification>
        <ConfigRoom
          visible={this.state.showConfigRoom}
          onAccesso={this.accessoSessione}></ConfigRoom>
        <InputOssimetro
          open={this.state.openInputOssimetro}
          onClose={this.closeInputDeviceOssimetro}
          onSend={(spo2, pr) => this.sendInputDeviceOssimetro(spo2, pr)}
        />
        <InputSfigmomanometro
          open={this.state.openInputSfigmo}
          onClose={this.closeInputDeviceSfigmo}
          onSend={(sys, dia) => this.sendInputDeviceSfigmo(sys, dia)}
        />
        <InputBilancia
          open={this.state.openInputBilancia}
          onClose={this.closeInputDeviceBilancia}
          onSend={(peso) => this.sendInputDeviceBilancia(peso)}
        />
        <InputGlucometro
          open={this.state.openInputGlucometro}
          onClose={this.closeInputDeviceGlucometro}
          onSend={(gli) => this.sendInputDeviceGlucometro(gli)}
        />
        <InputTermometro
          open={this.state.openInputTermometro}
          onClose={this.closeInputDeviceTermometro}
          onSend={(temp) => this.sendInputDeviceTermometro(temp)}
        />
        <AudioStetoPlayer
          open={this.state.openAudioStetoPlayer}
          urlPath={this.state.urlAudio.replace('/public/', '/stanza/file/')}
          onClose={async () => {
            //Ripristino Audio degli altri
            const elenco = this.state.subscribers;
            Array.from(elenco).forEach((sub) => {
              sub.muted = !sub.muted;
            });
            await this.setStateAsync({
              subscribers: elenco
            });
            await this.setStateAsync({
              urlAudio: '',
              openAudioStetoPlayer: false
            });
          }}
        />
        {super.render()}
      </div>
    );
  }
}

export default Engine;
