/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import ConfigRoom from './config-room';
import './index.css';

type Props = {};

class TestAudioVideo extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return <ConfigRoom></ConfigRoom>;
  }
}

export default TestAudioVideo;
