/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import './index.css';
import { Container } from '@mui/material';
import AbstractScreen from '@src/view/abstractscreen';
import WelcomeScreen from '@src/view/sessione/welcome';

type Props = {};

type State = {
  roomId: string;
  debug: boolean;
};

class LoginVideo extends AbstractScreen<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      roomId: '',
      debug: process.env.NODE_ENV === 'development'
    };
  }

  render() {
    return (
      <Container maxWidth="sm">
        <WelcomeScreen />
        {super.render()}
      </Container>
    );
  }
}

export default LoginVideo;
