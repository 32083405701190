import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogActions } from '@mui/material';
import data from '../../constant';

/**
 * buttons = [{ title: "OK", onItemPress: function(){} }, { title: "SI", onItemPress: function(){}  }]
 */

export type DialogButton = {
  id?: string;
  title: string;
  onItemPress: () => void;
};

type Props = {
  title?: string;
  message: string;
  type: 'success' | 'warning' | 'error';
  visible: boolean;
  buttons: DialogButton[];
};

const MaterialDialog = (props: Props) => {
  /**
   * Props
   */
  const {
    title = 'Attenzione',
    message = 'SubTitle',
    type = 'success',
    visible,
    buttons = []
  } = props;

  let image;
  switch (type) {
    case 'success':
      image = 'assets/images/success.png';
      break;
    case 'warning':
      image = 'assets/images/warning.png';
      break;
    case 'error':
      image = 'assets/images/error.png';
      break;
    default:
      image = '';
      break;
  }

  /**
   * Associa UUID ai bottoni
   */
  const setupButtons = () => {
    const elencoButtons: DialogButton[] = [];
    buttons.forEach((element) => {
      element.id = uuidv4();
      elencoButtons.push(element);
    });
    return elencoButtons;
  };

  /**
   * Vista
   */
  return (
    <Dialog open={visible}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          maxWidth: 450,
          minWidth: 250,
          minHeight: 100
        }}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
          <div
            style={{
              alignItems: 'center',
              margin: 20,
              height: 50
            }}>
            <img style={{ flex: 1, width: 50 }} alt={'Logo'} src={image} />
          </div>
          <div
            style={{
              flexDirection: 'column',
              marginRight: 15,
              marginTop: 15,
              alignSelf: 'center',
              fontSize: 16
            }}>
            <div style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 10 }}>{title}</div>
            <div style={{ fontSize: 16 }}>{message}</div>
          </div>
        </div>
        <DialogActions>
          {buttons.length !== 0 ? (
            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
              {setupButtons().map((item) => (
                <Button
                  style={{
                    float: 'right',
                    color: data.primary,
                    padding: 10,
                    fontSize: 16
                  }}
                  key={item.id}
                  onClick={() => item.onItemPress()}>
                  {item.title}
                </Button>
              ))}
            </div>
          ) : null}
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default MaterialDialog;
