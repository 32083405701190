import React from 'react';
import ReactDOM from 'react-dom';
import Login from './view/sessione/login';
import './index.css';
import Engine from './view/sessione/engine';
import '@src/utils/fonts/index.css';
import { Switch, Route, HashRouter, RouteComponentProps } from 'react-router-dom';
import Player from './view/videoPlayer/player';
import LoginVideo from './view/videoPlayer/loginplayer';
import TestAudioVideo from './view/testAudioVideo';
import { createTheme, ThemeProvider } from '@mui/material';
import data from './components/constant';
import NotFound404 from './view/notfound404';
import ShareDropScreen from './view/sessione/shareDrop';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://25a3d09a0e92d27b0447958d93a85703@o4506942497751040.ingest.us.sentry.io/4507185982734336',
  integrations: []
});

const theme = createTheme({
  palette: {
    primary: {
      main: data.primary
    },
    secondary: {
      main: data.secondary
    }
  }
});

export const Rotte = () => {
  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/video">
            <LoginVideo />
          </Route>
          <Route path="/test">
            <TestAudioVideo />
          </Route>
          <Route
            path="/session/:token"
            render={(props: RouteComponentProps<{ token: string }>) => <Engine {...props} />}
          />
          <Route
            path="/sharedrop/:token"
            render={(props: RouteComponentProps<{ token: string }>) => (
              <ShareDropScreen {...props} />
            )}
          />
          <Route
            path="/player/:token"
            render={(props: RouteComponentProps<{ token: string }>) => <Player {...props} />}
          />
          <Route component={NotFound404} />
        </Switch>
      </HashRouter>
    </ThemeProvider>
  );
};

ReactDOM.render(<Rotte />, document.getElementById('root'));
