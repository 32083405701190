/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'react';
import MaterialDialog, { DialogButton } from '@src/components/base/material-dialog';
import MaterialProgressDialog from '@src/components/base/material-progress';
import React from 'react';
import './abstractscreen.css';

type AbstractState = {
  showProgressDialog: boolean;
  messageProgress: string;

  showDialog: boolean;
  messageDialog: string;
  typeDialog: 'error' | 'success' | 'warning';
  buttonsDialog: DialogButton[];
};

interface State {
  [key: string]: any;
}

abstract class AbstractScreen<P, S> extends Component<P, AbstractState & S> {
  mounted: boolean;

  constructor(props: P) {
    super(props);
    this.mounted = false;

    this.state = {
      ...this.state,
      showProgressDialog: false,
      messageProgress: '',

      showDialog: false,
      messageDialog: '',
      typeDialog: 'success',
      buttonsDialog: []
    };

    this.nascondiProgressDialog = this.nascondiProgressDialog.bind(this);
    this.mostraProgressDialog = this.mostraProgressDialog.bind(this);
    this.nascondiDialog = this.nascondiDialog.bind(this);
    this.mostraDialog = this.mostraDialog.bind(this);
    this.setStateAsync = this.setStateAsync.bind(this);
    this.isMounted = this.isMounted.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  isMounted() {
    return this.mounted;
  }

  setStateAsync(this: React.Component<{}, State>, state: State): Promise<void> {
    return new Promise((resolve) => {
      this.setState(state, () => {
        resolve();
      });
    });
  }

  async nascondiProgressDialog() {
    await this.setStateAsync({
      ...this.state,
      showProgressDialog: false
    });
  }

  async mostraProgressDialog(message: string) {
    await this.setStateAsync({
      ...this.state,
      messageProgress: message,
      showProgressDialog: true
    });
  }

  /**
   * Nascondi Dialog
   */
  async nascondiDialog() {
    await this.setStateAsync({
      ...this.state,
      showDialog: false
    });
  }

  /**
   * Mostra Dialog
   * INPUT: messaggio
   */
  async mostraDialog(
    message: string,
    type: 'error' | 'success' | 'warning',
    buttons?: DialogButton[]
  ) {
    console.log('mostraDialog', message);
    await this.setStateAsync({
      ...this.state,
      messageDialog: message,
      showDialog: true,
      typeDialog: type
    });
    if (buttons !== undefined) {
      await this.setStateAsync({
        ...this.state,
        buttonsDialog: buttons
      });
    } else {
      const okButton: DialogButton = {
        title: 'ok',
        onItemPress: this.nascondiDialog.bind(this)
      };
      await this.setStateAsync({
        ...this.state,
        buttonsDialog: [okButton]
      });
    }
  }

  render() {
    return (
      <div style={{ position: 'absolute' }}>
        <MaterialProgressDialog
          visible={this.state.showProgressDialog}
          message={this.state.messageProgress}
        />
        <MaterialDialog
          visible={this.state.showDialog}
          message={this.state.messageDialog}
          buttons={this.state.buttonsDialog}
          type={this.state.typeDialog}
        />
      </div>
    );
  }
}

export default AbstractScreen;
