import React from 'react';

const WelcomeScreen = () => {
  return (
    <div className="container_404" style={{ textAlign: 'center' }}>
      <img
        style={{ width: '50vw', marginLeft: 5, marginRight: 5, height: 300 }}
        alt="pdf"
        src={'assets/images/doctor.svg'}
      />
      <h2>
        Per utilizzare il servizio &quot;Sessione Video&quot; utilizzare la piattaforma per
        accedere.
      </h2>
    </div>
  );
};

export default WelcomeScreen;
